import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import Footer from "./Footer";
import Header from "./Header";
import { useSelector } from "react-redux";

function News() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    getBlogsData();
  }, []);

  const getBlogsData = async () => {
    const response = await SendPostRequest("news/list");
    setNewsData(response?.data);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Latest News</h2>
          </div>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="cs_height_72 cs_height_lg_50"></div>
          <div className="row gy-4">
            {newsData?.map((data, index) => (
              <div key={index} className="col-lg-4 col-md-6 col-12">
                <div className="cs_post cs_style_1 facility-card">
                  <div
                    style={{
                      backgroundImage: `url(${data?.image})`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      height: "360px",
                      width: "100%",
                      cursor: "pointer",
                    }}
                  />
                  <div className="cs_post_info">
                    <div>
                      <h2 className="cs_post_title cs_semibold cs_fs_32">
                        {data?.title}
                      </h2>

                      <p
                        style={{ textAlign: "justify" }}
                        dangerouslySetInnerHTML={{
                          __html: data?.content?.slice(0, 110),
                        }}
                      />

                      <Link
                        to={`/news/detail/${data?.slug}`}
                        // to={`/service/${service?.service_slug}`}
                      >
                        <button className="service-read-more">Read More</button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}

export default News;
