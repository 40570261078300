import React, { useEffect, useState } from "react";
import { SendPostRequest } from "../http";
import { Link } from "react-router-dom";
import Spinner from "./Spinner";
import { formatDate } from "../Helper/Helper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeNews() {
  const [newsData, setNewsData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {
    const response = await SendPostRequest("news/list");
    setNewsData(response?.data || []);
    setLoading(false);
  };

  const truncateText = (text, maxLength, slug) => {
    if (text.length > maxLength) {
      return (
        <>
          {text.slice(0, maxLength)}...{" "}
          <Link to={`/news/detail/${slug}`}>
            <button className="news_home_see-more-btn">Read More</button>
          </Link>
        </>
      );
    }
    return text;
  };

  const settings = {
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Rendered news items
  const newsItems = newsData?.map((data, index) => (
    <div key={index} className="col-lg-4 col-md-6 col-12 mb-3">
      <div className="cs_post cs_style_1 facility-card new_home_btn">
        {/* <div
          style={{
            backgroundImage: `url(${data?.image})`,
            backgroundPosition: "top center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            height: "250px",
            width: "100%",
            cursor: "pointer",
          }}
        /> */}
        <img src={data?.image} alt="news image" />
        <div className="cs_post_info">
          <div>
            <h2 className="cs_post_title cs_semibold cs_fs_32">
              {data?.title}
            </h2>
            <div className="cs_post_meta author_detail_heading-wrapper mb-3">
              <h3>Publish Date</h3>
              <div className="cs_posted_by">{formatDate(data?.date)}</div>
            </div>
            <p>{truncateText(data?.content, 90, data?.slug)}</p>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : newsData?.length !== 0 ? (
        <section>
          <div className="cs_height_60 "></div>
          <div className="container">
            <div className="featured_services_heading_wrapper pb-5">
              <h2 className="cs_section_title cs_fs_65 m-0">Latest News</h2>
              <Link to="/news">
                <button className="featured_services_view_btn">View All</button>
              </Link>
            </div>
            <div className="cs_height_50"></div>

            {/* Desktop View */}
            <div className="featured_services_desktop">
              <div className="row gy-3">{newsItems}</div>
            </div>

            {/* Mobile View */}
            <div className="featured_services_mobile">
              <Slider {...settings}>{newsItems}</Slider>
            </div>
          </div>
        </section>
      ) : (
        <p>No news data found.</p>
      )}
    </div>
  );
}

export default HomeNews;
