import React from "react";

function ChildernHeightPredictor() {
  const buttonRef = React.createRef();
  function gIsNumber(strNumber, strMin, strMax) {
    /**
     *gIsNumber(strNumber,strMin,strMax)
     *strMin and strMax may be null.
     *if strMin and/or strMax are not null, all are verified as numbers,
     *and strMin<=strNumber and/or strNumber<=strMax
     */
    gRegister("gIsNumber");
    var strTempNumber = strNumber; //make a copy
    strTempNumber = "" + strTempNumber; //make sure copy is string
    if (strTempNumber.length == 0) return false;
    for (var i = 0; i < strTempNumber.length; i++) {
      if (
        !(
          strTempNumber.charAt(i) == "0" ||
          strTempNumber.charAt(i) == "1" ||
          strTempNumber.charAt(i) == "2" ||
          strTempNumber.charAt(i) == "3" ||
          strTempNumber.charAt(i) == "4" ||
          strTempNumber.charAt(i) == "5" ||
          strTempNumber.charAt(i) == "6" ||
          strTempNumber.charAt(i) == "7" ||
          strTempNumber.charAt(i) == "8" ||
          strTempNumber.charAt(i) == "9" ||
          strTempNumber.charAt(i) == "-" ||
          strTempNumber.charAt(i) == "."
        )
      ) {
        return false;
      }
    }
    if (strTempNumber.indexOf(".") != -1) {
      var strDecident = strTempNumber.substr(strTempNumber.indexOf("."));
      var blnBadDecident = false;
      if (strDecident == ".") blnBadDecident = true;
      for (var i = 1; i < strDecident.length; i++) {
        if (strDecident.charAt(i) == "." || strDecident.charAt(i) == "-") {
          blnBadDecident = true;
        }
      }
      if (blnBadDecident) {
        return false;
      }
    }

    if (strTempNumber.indexOf("-") != -1) {
      //if it includes a - test:
      if (strTempNumber.length == 1) return false; //bad if - is the only character
      if (strTempNumber.indexOf("-") != 0) return false; //bad if - is not the first character
    }

    if (strMin) {
      var strTempMin = strMin;
      strTempMin = "" + strTempMin;
      if (strTempMin.length == 0) return false;
      for (var i = 0; i < strTempMin.length; i++) {
        if (
          !(
            strTempMin.charAt(i) == "0" ||
            strTempMin.charAt(i) == "1" ||
            strTempMin.charAt(i) == "2" ||
            strTempMin.charAt(i) == "3" ||
            strTempMin.charAt(i) == "4" ||
            strTempMin.charAt(i) == "5" ||
            strTempMin.charAt(i) == "6" ||
            strTempMin.charAt(i) == "7" ||
            strTempMin.charAt(i) == "8" ||
            strTempMin.charAt(i) == "9" ||
            strTempMin.charAt(i) == "-" ||
            strTempMin.charAt(i) == "."
          )
        ) {
          return false;
        }
      }
      if (strNumber < parseFloat(strMin)) return false;
    }
    if (strMax) {
      var strTempMax = strMax;
      strTempMax = "" + strTempMax;
      if (strTempMax.length == 0) return false;
      for (var i = 0; i < strTempMax.length; i++) {
        if (
          !(
            strTempMax.charAt(i) == "0" ||
            strTempMax.charAt(i) == "1" ||
            strTempMax.charAt(i) == "2" ||
            strTempMax.charAt(i) == "3" ||
            strTempMax.charAt(i) == "4" ||
            strTempMax.charAt(i) == "5" ||
            strTempMax.charAt(i) == "6" ||
            strTempMax.charAt(i) == "7" ||
            strTempMax.charAt(i) == "8" ||
            strTempMax.charAt(i) == "9" ||
            strTempMin.charAt(i) == "-" ||
            strTempMax.charAt(i) == "."
          )
        ) {
          return false;
        }
      }
      if (strNumber > parseFloat(strMax)) return false;
    }
    gUnregister();
    return true;
  }
  function gMakeNumber(strNumber, strAltValue) {
    if (gIsNumber(strNumber)) return parseInt(strNumber);
    else return strAltValue;
  }

  function gRegister(strFunctionCall) {
    var strCaller = "";
    strCaller = strFunctionCall;
    return true;
  }
  function gUnregister() {
    var strCaller = "";
    return true;
  }
  function gToInches(strFeet, strInches, strMin, strMax) {
    gRegister("gToInches");
    if (!gIsNumber(strFeet)) return false;
    if (!gIsNumber(strInches)) return false;
    strInches += strFeet * 12;
    if (strMin) {
      if (!gIsNumber(strMin)) return false;
      if (strInches < strMin) return false;
    }
    if (strMax) {
      if (!gIsNumber(strMax)) return false;
      if (strInches > strMax) return false;
    }
    gUnregister();
    return strInches;
  }
  function calc(
    beta0,
    beta1,
    beta2,
    beta3,
    mHeight,
    fHeight,
    cHeight,
    cWeight
  ) {
    // Adultheight = 0M + 1M*childHeight + 2M*childWeight + 3M*parentsHeightsAvg
    var pAvgHeight = (mHeight + fHeight) / 2;
    var aHeight =
      beta0 + beta1 * cHeight + beta2 * cWeight + beta3 * pAvgHeight;
    return Math.round(aHeight); // in Inch
  }
  function gToFeetAndInches(strInches, strMin, strMax) {
    gRegister("gToFeetAndInches");
    if (!gIsNumber(strInches)) return false;
    if (strMin) {
      if (!gIsNumber(strMin)) return false;
      if (strInches < strMin) return false;
    }
    if (strMax) {
      if (!gIsNumber(strMax)) return false;
      if (strInches > strMax) return false;
    }
    var strReturnValue = "";
    strReturnValue =
      (strInches - (parseInt(strInches) % 12)) / 12 +
      "'" +
      (parseInt(strInches) % 12) +
      '"';
    gUnregister();
    return strReturnValue;
  }
  function ChildHeightPredictor(callerObj) {
    //alert( $("#ChildHeightForm #age").val() );

    var age =
      callerObj.form.age.options[callerObj.form.age.selectedIndex].value;
    if (callerObj.form.gender[0].checked) {
      var gender = "female";
    } else if (callerObj.form.gender[1].checked) {
      var gender = "male";
    } else {
      return;
    }

    //alert(gender + " - " + age);

    var mFeetVal = gMakeNumber(callerObj.form.mFeet.value, 0);
    var mInchVal = gMakeNumber(callerObj.form.mInch.value, 0);
    var mNewFeetVal = Math.floor(mInchVal / 12);
    mInchVal = mInchVal % 12;
    callerObj.form.mInch.value = mInchVal;
    mFeetVal += mNewFeetVal;

    var fFeetVal = gMakeNumber(callerObj.form.fFeet.value, 0);
    var fInchVal = gMakeNumber(callerObj.form.fInch.value, 0);
    var fNewFeetVal = Math.floor(fInchVal / 12);
    fInchVal = fInchVal % 12;
    callerObj.form.fInch.value = fInchVal;
    fFeetVal += fNewFeetVal;

    var cFeetVal = gMakeNumber(callerObj.form.cFeet.value, 0);
    var cInchVal = gMakeNumber(callerObj.form.cInch.value, 0);
    var cNewFeetVal = Math.floor(cInchVal / 12);
    cInchVal = cInchVal % 12;
    callerObj.form.cInch.value = cInchVal;
    cFeetVal += cNewFeetVal;

    var cW = gMakeNumber(callerObj.form.cWeight.value, 0);

    // must have mother and father info:
    if (mFeetVal <= 0 && mInchVal <= 0) {
      alert("There appears to be an error in the mother's height.");
      return;
    }
    if (fFeetVal <= 0 && fInchVal <= 0) {
      alert("There appears to be an error in the father's height.");
      return;
    }
    if (age != "LT4" && cFeetVal <= 0 && cInchVal <= 0) {
      alert("There appears to be an error in the child's height.");
      return;
    }
    if (age != "LT4" && cW <= 0) {
      alert("There appears to be an error in the child's weight.");
      return;
    }
    if (!gender) {
      alert("Please select your child's gender.");
      return;
    }

    var rs = 0;

    var mH = gToInches(mFeetVal, mInchVal);
    var fH = gToInches(fFeetVal, fInchVal);
    var cH = gToInches(cFeetVal, cInchVal);

    if (gender == "male") {
      if (age == "LT4") rs = calc(3.0, 0, 0, 1.0, mH, fH, cH, cW);
      if (age == "040")
        rs = calc(-10.2567, 1.23812, -0.08724, 0.50286, mH, fH, cH, cW);
      if (age == "045")
        rs = calc(-10.719, 1.15964, -0.07445, 0.52887, mH, fH, cH, cW);
      if (age == "050")
        rs = calc(-11.0213, 1.10674, -0.06478, 0.53919, mH, fH, cH, cW);
      if (age == "055")
        rs = calc(-11.1556, 1.0748, -0.05776, 0.53691, mH, fH, cH, cW);
      if (age == "060")
        rs = calc(-11.1138, 1.05923, -0.05295, 0.52513, mH, fH, cH, cW);
      if (age == "065")
        rs = calc(-11.0221, 1.05542, -0.04989, 0.50692, mH, fH, cH, cW);
      if (age == "070")
        rs = calc(-10.9984, 1.05877, -0.04814, 0.48538, mH, fH, cH, cW);
      if (age == "075")
        rs = calc(-11.0214, 1.06467, -0.04726, 0.46361, mH, fH, cH, cW);
      if (age == "080")
        rs = calc(-11.0696, 1.06853, -0.04678, 0.44469, mH, fH, cH, cW);
      if (age == "085")
        rs = calc(-11.122, 1.06572, -0.04626, 0.43171, mH, fH, cH, cW);
      if (age == "090")
        rs = calc(-11.1571, 1.05166, -0.04525, 0.42776, mH, fH, cH, cW);
      if (age == "095")
        rs = calc(-11.1405, 1.02174, -0.04331, 0.43593, mH, fH, cH, cW);
      if (age == "100")
        rs = calc(-11.038, 0.97135, -0.03998, 0.45932, mH, fH, cH, cW);
      if (age == "105")
        rs = calc(-10.8286, 0.89589, -0.03481, 0.50101, mH, fH, cH, cW);
      if (age == "110")
        rs = calc(-10.4917, 0.81239, -0.02905, 0.54781, mH, fH, cH, cW);
      if (age == "115")
        rs = calc(-10.0065, 0.74134, -0.02417, 0.58409, mH, fH, cH, cW);
      if (age == "120")
        rs = calc(-9.3522, 0.68325, -0.02008, 0.60927, mH, fH, cH, cW);
      if (age == "123")
        rs = calc(-8.6055, 0.63869, -0.01668, 0.62279, mH, fH, cH, cW);
      if (age == "130")
        rs = calc(-7.8632, 0.60818, -0.0139, 0.62407, mH, fH, cH, cW);
      if (age == "135")
        rs = calc(-7.1348, 0.59228, -0.01162, 0.61253, mH, fH, cH, cW);
      if (age == "140")
        rs = calc(-6.4299, 0.59151, -0.00978, 0.58762, mH, fH, cH, cW);
      if (age == "145")
        rs = calc(-5.7578, 0.60643, -0.00826, 0.54875, mH, fH, cH, cW);
      if (age == "150")
        rs = calc(-5.1282, 0.63757, -0.00699, 0.49536, mH, fH, cH, cW);
      if (age == "155")
        rs = calc(-4.5092, 0.68548, -0.00586, 0.42687, mH, fH, cH, cW);
      if (age == "160")
        rs = calc(-3.9292, 0.75069, -0.0048, 0.34271, mH, fH, cH, cW);
      if (age == "165")
        rs = calc(-3.4873, 0.83375, -0.0037, 0.24231, mH, fH, cH, cW);
      if (age == "170")
        rs = calc(-3.283, 0.9352, -0.00247, 0.1251, mH, fH, cH, cW);
      if (age == "175")
        rs = calc(-3.4156, 1.05558, -0.00103, -0.0095, mH, fH, cH, cW);
    } else if (gender == "female") {
      if (age == "LT4") rs = calc(-3.0, 0, 0, 1.0, mH, fH, cH, cW);
      if (age == "040")
        rs = calc(-8.1325, 1.24768, -0.19435, 0.44774, mH, fH, cH, cW);
      if (age == "045")
        rs = calc(-6.47656, 1.22177, -0.18519, 0.41381, mH, fH, cH, cW);
      if (age == "050")
        rs = calc(-5.13583, 1.19932, -0.1753, 0.3846, mH, fH, cH, cW);
      if (age == "055")
        rs = calc(-4.13791, 1.1788, -0.16484, 0.36039, mH, fH, cH, cW);
      if (age == "060")
        rs = calc(-3.51039, 1.15866, -0.154, 0.34105, mH, fH, cH, cW);
      if (age == "065")
        rs = calc(-3.14322, 1.13737, -0.14294, 0.32672, mH, fH, cH, cW);
      if (age == "070")
        rs = calc(-2.87645, 1.11342, -0.13184, 0.31748, mH, fH, cH, cW);
      if (age == "075")
        rs = calc(-2.66291, 1.08525, -0.12086, 0.3134, mH, fH, cH, cW);
      if (age == "080")
        rs = calc(-2.45559, 1.05135, -0.11019, 0.31457, mH, fH, cH, cW);
      if (age == "085")
        rs = calc(-2.20728, 1.01018, -0.09999, 0.32105, mH, fH, cH, cW);
      if (age == "090")
        rs = calc(-1.87098, 0.9602, -0.09044, 0.33291, mH, fH, cH, cW);
      if (age == "095")
        rs = calc(-1.0633, 0.89989, -0.08171, 0.35025, mH, fH, cH, cW);
      if (age == "100")
        rs = calc(0.33468, 0.82771, -0.07397, 0.37312, mH, fH, cH, cW);
      if (age == "105")
        rs = calc(1.97366, 0.74213, -0.06739, 0.40161, mH, fH, cH, cW);
      if (age == "110")
        rs = calc(3.50436, 0.67173, -0.06136, 0.42042, mH, fH, cH, cW);
      if (age == "115")
        rs = calc(4.57747, 0.6415, -0.05518, 0.41686, mH, fH, cH, cW);
      if (age == "120")
        rs = calc(4.84365, 0.64452, -0.04894, 0.3949, mH, fH, cH, cW);
      if (age == "125")
        rs = calc(4.27869, 0.67386, -0.04272, 0.3585, mH, fH, cH, cW);
      if (age == "130")
        rs = calc(3.21417, 0.7226, -0.03661, 0.31163, mH, fH, cH, cW);
      if (age == "135")
        rs = calc(1.83456, 0.78383, -0.03067, 0.25826, mH, fH, cH, cW);
      if (age == "140")
        rs = calc(0.32425, 0.85062, -0.025, 0.20235, mH, fH, cH, cW);
      if (age == "145")
        rs = calc(-1.13224, 0.91605, -0.01967, 0.14787, mH, fH, cH, cW);
      if (age == "150")
        rs = calc(-2.35055, 0.97319, -0.01477, 0.0988, mH, fH, cH, cW);
      if (age == "155")
        rs = calc(-3.10326, 1.01514, -0.01037, 0.05909, mH, fH, cH, cW);
      if (age == "160")
        rs = calc(-3.17885, 1.03496, -0.00655, 0.03272, mH, fH, cH, cW);
      if (age == "165")
        rs = calc(-2.41657, 1.02573, -0.0034, 0.02364, mH, fH, cH, cW);
      if (age == "170")
        rs = calc(-0.65579, 0.98054, -0.001, 0.03584, mH, fH, cH, cW);
      if (age == "175")
        rs = calc(2.26429, 0.89246, 0.00057, 0.07327, mH, fH, cH, cW);
    }

    //deal with extreme values
    if (rs <= 0 || rs < cH) {
      alert(
        "We are unable to calculate an adult height\n" +
          "for your child based on the information you have provided\n\n" +
          "Please check to be sure you have entered the correct values"
      );
    } else {
      callerObj.form.result.value = gToFeetAndInches(rs);
    }
  }
  return (
    <>
      <div id="children_height_tab">
        <div class="row">
          <div className="col-md-6">
            <h3>Height Calculator - Children's Height Predictor</h3>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              Do you want to know your child's height when he is an adult? Try
              this Children's Adult Height Predictor calculator for boys and
              girls, which predicts the height of your child in the future based
              on child and parents data. Select the child's gender (boy or girl)
              and age, to the closest half year. Enter the child's weight and
              height. Enter the mother's and father's height. Click on Calculate
              for the estimated adult height.
            </p>
            <div className="clearfix">
              <br />
            </div>
            <h3>Children Height Growth Prediction</h3>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              A child's height is controlled by genetics, but the genetic height
              is not an absolute. It gives us a range, and the exact height of
              your child would depend upon many other factors like nutrition,
              stress, and general health status.
            </p>
            <div className="clearfix">
              <br />
            </div>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              There's no definite way to predict a child's adult height
              precisely. However, some formulas can provide an estimate for
              child growth. Here's a popular example:
            </p>
            <div className="clearfix">
              <br />
            </div>
            <p style={{ fontSize: 16, textAlign: "justify" }}></p>
            <ul style={{ marginLeft: 25 }}>
              <li className="list">
                <strong>
                  Add the mother's height and the father's height in either
                  inches or centimeters.
                </strong>
              </li>
              <li className="list">
                <strong>
                  Add 5 inches (13 centimeters) for boys or subtract 5 inches
                  (13 centimeters) for girls.
                </strong>
              </li>
              <li className="list">
                <strong>Divide by two.</strong>
              </li>
            </ul>
            <p></p>
            <div className="clearfix">
              <br />
            </div>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              Most children will reach an adult height within 4 inches (10
              centimeters) of this estimation. The average height for each sex
              within a population varies significantly, with men usually being
              taller than women. Women ordinarily reach their greatest height at
              a younger age than men because puberty generally occurs earlier in
              women than in men. Vertical growth stops when the long bones stop
              growing long.
            </p>
          </div>
          <div class="col-md-6">
            <div style={{ width: "100%" }}>
              <div className="body-fat-ratio-heading">
                <h5> Children's Adult Height Predictor for Boys & Girls</h5>
              </div>

              <div style={{ backgroundColor: "#F7F7F7" }}>
                <div class="clearfix">
                  <br />
                </div>
                <form
                  method="post"
                  name="ChildHeightForm"
                  id="ChildHeightForm"
                  style={{
                    width: "95%",
                    marginLeft: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div class="row" style={{ borderBottom: "0px" }}>
                    <div class="col-lg-5">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Child's Sex
                      </label>
                      <div class="input-group">
                        <input
                          checked="checked"
                          value="female"
                          name="gender"
                          type="radio"
                        />
                        <label
                          for="radio_1"
                          style={{ marginRight: "15px" }}
                          className="ms-2"
                        >
                          Girl
                        </label>

                        <input value="male" name="gender" type="radio" />
                        <label for="radio_1" className="ms-2">
                          Boy
                        </label>
                      </div>
                    </div>
                    <div class="col-lg-7">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Child Age
                      </label>
                      <label class="pull-right">Years</label>
                      <font face="Arial,Helvetica,Geneva,Swiss,SunSans-Regular">
                        <select name="age" id="age" class="form-control">
                          <option value="LT4">&lt;4</option>
                          <option value="040">4.0</option>
                          <option value="045">4.5</option>
                          <option value="050">5.0</option>
                          <option value="055">5.5</option>
                          <option value="060">6.0</option>
                          <option value="065">6.5</option>
                          <option value="070">7.0</option>
                          <option value="075">7.5</option>
                          <option value="080">8.0</option>
                          <option value="085">8.5</option>
                          <option value="090">9.0</option>
                          <option value="095">9.5</option>
                          <option value="100">10.0</option>
                          <option value="105">10.5</option>
                          <option value="110">11.0</option>
                          <option value="115">11.5</option>
                          <option value="120">12.0</option>
                          <option value="120">12.3</option>
                          <option value="130">13.0</option>
                          <option value="135">13.5</option>
                          <option value="140">14.0</option>
                          <option value="145">14.5</option>
                          <option value="150">15.0</option>
                          <option value="155">15.5</option>
                          <option value="160">16.0</option>
                          <option value="165">16.5</option>
                          <option value="170">17.0</option>
                          <option value="175">17.5</option>
                        </select>
                      </font>
                    </div>
                    <div class="col-lg-12 my-3">
                      <h5>Child Height</h5>
                      <hr
                        style={{
                          marginBottom: "-8px",
                          borderTop: "0.2px solid lightgray",
                        }}
                      />
                    </div>
                    <div class="col-lg-6">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Feet
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="cFeet"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Inches
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="cInch"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-12 my-3">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Child Weight
                      </label>
                      <label class="pull-right">
                        <strong>Pounds</strong>
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="cWeight"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-12 my-3">
                      <h5>Mother Height</h5>
                      <hr
                        style={{
                          marginBottom: "-8px",
                          borderTop: "0.2px solid lightgray",
                        }}
                      />
                    </div>
                    <div class="col-lg-6">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Feet
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="mFeet"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Inches
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="mInch"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-12 my-3">
                      <h5>Father Height</h5>
                      <hr
                        style={{
                          marginBottom: "-8px",
                          borderTop: "0.2px solid lightgray",
                        }}
                      />
                    </div>
                    <div class="col-lg-6">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Feet
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="fFeet"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-6">
                      <label
                        class="control-label mb-10"
                        for="exampleInputuname_1"
                      >
                        Inches
                      </label>
                      <input
                        onfocus="clear_field(this)"
                        size="5"
                        // value=""
                        name="fInch"
                        class="form-control"
                      />
                    </div>
                    <div class="col-lg-12 my-5 d-flex justify-content-between">
                      <button
                        ref={buttonRef}
                        onClick={() => ChildHeightPredictor(buttonRef.current)}
                        // onclick="ChildHeightPredictor(this)"
                        name="Submit2"
                        // style={{ marginLeft: "105px" }}
                        type="button"
                        class=""
                      >
                        Calculate
                      </button>
                      <button
                        name="reset"
                        onclick="$('ChildHeightForm').reset();"
                        value="Clear Values"
                        type="reset"
                        // style={{ marginLeft: "28px" }}
                        class=""
                      >
                        Clear Values
                      </button>
                    </div>
                  </div>
                  <p class="text-center alert alert-success">
                    <strong>Your Child Prediction Height</strong>
                  </p>
                  <div class="col-lg-12">
                    <label
                      class="control-label mb-10"
                      for="exampleInputuname_1"
                    >
                      Predict Adult Height
                    </label>
                    <label class="pull-right">
                      <strong>At 21 Years</strong>
                    </label>
                    <input
                      readonly=""
                      size="5"
                      name="result"
                      class="form-control"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChildernHeightPredictor;
