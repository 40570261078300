import React from "react";

function WeightConverter() {
  function ucDCUpdateResult(usdcType) {
    var processingType = usdcType;
    var ucfromvalue = cleanNumberInput(document.getElementById("ucfrom").value);
    var ucfromunit = document.getElementById("ucfromunit");
    var uctounit = document.getElementById("uctounit");
    var uctoid = document.getElementById("ucto");
    if (usdcType == 1) {
      ucfromvalue = cleanNumberInput(document.getElementById("ucto").value);
      ucfromunit = document.getElementById("uctounit");
      uctounit = document.getElementById("ucfromunit");
      uctoid = document.getElementById("ucfrom");
    }
    var ucfromunitvalue = ucfromunit.value;
    var uctounitvalue = uctounit.value;
    var noValidation;
    if (noValidation == 1) {
      ucfromvalue = trimAll((ucfromvalue + "").toUpperCase());

      var ucfromunitvalueArray = ucParseSelectValue(ucfromunitvalue);
      var uctounitvalueArray = ucParseSelectValue(uctounitvalue);
      var tempBaseNum = parseInt(
        ucfromunitvalueArray[1].substr(2).replace("]", "")
      );
      var tempTestStr = " 0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      var tempRegStr = "";
      if (tempBaseNum > 10) {
        tempRegStr = "^[0-9A-" + tempTestStr.substr(tempBaseNum, 1) + "]+$";
      } else {
        tempRegStr = "^[0-" + tempTestStr.substr(tempBaseNum, 1) + "]+$";
      }
      var reg = new RegExp(tempRegStr);
      if (reg.test(ucfromvalue)) {
        var tempResult = 1;
        tempResult = ucCalculateResultNumOnly(
          ucfromvalue,
          ucfromunitvalueArray[1],
          uctounitvalueArray[1]
        );
        if (uctounitvalueArray[1].indexOf(":") > 0) {
          var tempUntArray = uctounitvalueArray[1].split(":");
          if (tempUntArray[0] != "3") {
            tempResult = gnumberFormat(tempResult);
          }
        } else {
          tempResult = gnumberFormat(tempResult);
        }
        uctoid.value = tempResult;
        document.getElementById("ucresult").innerHTML =
          "<font color='red'><b>Result:</b></font> " +
          ucfromvalue +
          " " +
          ucfromunitvalueArray[0] +
          " = " +
          tempResult +
          " " +
          uctounitvalueArray[0] +
          "";
        document.getElementById("ucresult").style.color = "black";
        //document.getElementById("ucresult").style.border = "2px solid #406b04";
      } else {
        if (ucfromvalue.length > 0) {
          document.getElementById("ucresult").innerHTML =
            "Please provide a valid number!";
          document.getElementById("ucresult").style.color = "red";
          //document.getElementById("ucresult").style.border = "2px solid #406b04";
        } else {
          document.getElementById("ucresult").innerHTML = "";
          //document.getElementById("ucresult").style.border = "2px solid #ffffff";
        }
        uctoid.value = "";
      }
    } else {
      if (!isNumber(ucfromvalue)) {
        if (ucfromvalue.length > 0) {
          document.getElementById("ucresult").innerHTML =
            "Please provide a valid number!";
          document.getElementById("ucresult").style.color = "red";
          //document.getElementById("ucresult").style.border = "2px solid #406b04";
        } else {
          document.getElementById("ucresult").innerHTML = "";
          //document.getElementById("ucresult").style.border = "2px solid #ffffff";
        }
        uctoid.value = "";
      } else {
        var ucfromunitvalueArray = ucParseSelectValue(ucfromunitvalue);
        var uctounitvalueArray = ucParseSelectValue(uctounitvalue);
        var tempResult = 1;
        var tempResultMore = "";
        if (ucfromunitvalueArray.length > 2) {
          if (uctounitvalueArray.length > 2) {
            tempResult = ucCalculateResultNumOnly(
              ucfromvalue,
              ucfromunitvalueArray[2],
              uctounitvalueArray[2]
            );
            if (
              uctounitvalueArray[0] == "foot" ||
              uctounitvalueArray[0] == "inch"
            ) {
            //   tempResultMore = convertFIToFra(
            //     tempResult,
            //     uctounitvalueArray[0]
            //   );
            }
          } else {
            tempResult = ucCalculateResultNumOnly(
              ucfromvalue,
              ucfromunitvalueArray[2],
              uctounitvalueArray[1]
            );
            
          }
        } else {
          if (uctounitvalueArray.length > 2) {
            tempResult = ucCalculateResultNumOnly(
              ucfromvalue,
              ucfromunitvalueArray[1],
              uctounitvalueArray[2]
            );
            if (
              uctounitvalueArray[0] == "foot" ||
              uctounitvalueArray[0] == "inch"
            ) {
            //   tempResultMore = convertFIToFra(
            //     tempResult,
            //     uctounitvalueArray[0]
            //   );
            }
          } else {
            tempResult = ucCalculateResultNumOnly(
              ucfromvalue,
              ucfromunitvalueArray[1],
              uctounitvalueArray[1]
            );
          }
        }
        tempResult = gnumberFormat(tempResult);
        uctoid.value = tempResult;
        document.getElementById("ucresult").innerHTML =
          "<font color='red'><b>Result:</b></font> " +
          ucfromvalue +
          " " +
          ucfromunitvalueArray[0] +
          " = " +
          tempResult +
          " " +
          uctounitvalueArray[0] +
          tempResultMore;
        document.getElementById("ucresult").style.color = "black";
        //document.getElementById("ucresult").style.border = "2px solid #406b04";
      }
    }
  }
  function cleanNumberInput(inVal) {
    var tempVal = inVal + "";
    while (
      tempVal.indexOf(" ") > -1 ||
      tempVal.indexOf("	") > -1 ||
      tempVal.indexOf(",") > -1
    ) {
      tempVal = tempVal.replace(" ", "").replace("	", "").replace(",", "");
    }
    return tempVal;
  }
  function ucParseSelectValue(inStr) {
    var tempArray = inStr.split("[");
    var ucOutArray = [];
    ucOutArray.push(trimAll(tempArray[0]));
    ucOutArray.push(trimAll(tempArray[1].replace("]", "")));
    if (tempArray.length > 2) {
      ucOutArray.push(trimAll(tempArray[2].replace("]", "")));
    }
    return ucOutArray;
  }
  function ucCalculateResultNumOnly(inVal, inFrom, inTo) {
    var tempResult = 0;
    var tempInVal = inVal;
    var tempInFrom = inFrom + "";
    var tempInTo = inTo + "";
    if (tempInFrom.indexOf(":") > 0 || tempInTo.indexOf(":") > 0) {
      var tempArrayFrom = tempInFrom.split(":");
      var tempArrayTo = tempInTo.split(":");
      if (tempArrayFrom.length == 3 || tempArrayTo.length == 3) {
        // Temperature
        eval(
          "tempResult = ((" +
            inVal +
            "-(" +
            tempArrayFrom[2] +
            "))/((" +
            tempArrayFrom[1] +
            ")-(" +
            tempArrayFrom[2] +
            ")))*((" +
            tempArrayTo[1] +
            ")-(" +
            tempArrayTo[2] +
            "))+(" +
            tempArrayTo[2] +
            ");"
        );
      } else {
        if ("3" == tempArrayFrom[0] || "3" == tempArrayTo[0]) {
          //Binary
          //alert("tempResult = (parseInt("+inVal+", "+tempArrayFrom[1]+")).toString("+tempArrayTo[1]+");");
          eval(
            'tempResult = (parseInt("' +
              inVal +
              '", ' +
              tempArrayFrom[1] +
              ")).toString(" +
              tempArrayTo[1] +
              ");"
          );
          return (tempResult + "").toUpperCase();
        } else {
          if (tempInFrom.indexOf(":") > 0) {
            if (tempInTo.indexOf(":") > 0) {
              eval(
                "tempResult = " +
                  tempInVal +
                  "*" +
                  tempArrayTo[1] +
                  "/" +
                  tempArrayFrom[1]
              );
            } else {
              eval(
                "tempResult = 1/" +
                  tempInVal +
                  "*" +
                  tempArrayFrom[1] +
                  "*" +
                  tempInTo
              );
            }
          } else {
            eval(
              "tempResult = 1/" +
                tempInVal +
                "*" +
                tempInFrom +
                "*" +
                tempArrayTo[1]
            );
          }
        }
      }
    } else {
      eval("tempResult = " + tempInVal + "*" + tempInTo + "/" + tempInFrom);
    }
    return tempResult;
  }
  function trimAll(sString) {
    while (sString.substring(0, 1) == " ") {
      sString = sString.substring(1, sString.length);
    }
    while (sString.substring(sString.length - 1, sString.length) == " ") {
      sString = sString.substring(0, sString.length - 1);
    }
    return sString;
  }
  function gnumberFormat(valToBeFormated) {
    var gniTotalDigits = 3;
    var gniPareSize = 12;
    var valStr = "" + valToBeFormated;
    if (
      valStr.indexOf("N") >= 0 ||
      (valToBeFormated == 2 * valToBeFormated &&
        valToBeFormated == 1 + valToBeFormated)
    )
      return "Error ";
    var i = valStr.indexOf("e");
    if (i >= 0) {
      var expStr = valStr.substring(i + 1, valStr.length);
      if (i > 11) i = 11; // max 11 digits
      valStr = valStr.substring(0, i);
      if (valStr.indexOf(".") < 0) {
        valStr += ".";
      } else {
        // remove trailing zeros
        var j = valStr.length - 1;
        while (j >= 0 && valStr.charAt(j) == "0") --j;
        valStr = valStr.substring(0, j + 1);
      }
      valStr += "E" + expStr;
    } else {
      var valNeg = false;
      if (valToBeFormated < 0) {
        valToBeFormated = -valToBeFormated;
        valNeg = true;
      }
      var valInt = Math.floor(valToBeFormated);
      var valFrac = valToBeFormated - valInt;
      var prec = gniTotalDigits - ("" + valInt).length - 1; // how many digits available after period
  
      var mult = " 1000000000000000000".substring(1, prec + 2);
      if (mult == "" || mult == " ") {
        mult = 1;
      } else {
        mult = parseInt(mult);
      }
      var frac = Math.floor(valFrac * mult + 0.5);
      valInt = Math.floor(Math.floor(valToBeFormated * mult + 0.5) / mult);
      if (valNeg) valStr = "-" + valInt;
      else valStr = "" + valInt;
      var fracStr = "00000000000000" + frac;
      fracStr = fracStr.substring(fracStr.length - prec, fracStr.length);
      i = fracStr.length - 1;
  
      // remove trailing zeros unless fixed during entry.
      while (i >= 0 && fracStr.charAt(i) == "0") --i;
      fracStr = fracStr.substring(0, i + 1);
      if (i >= 0) valStr += "." + fracStr;
    }
    return valStr;
  }
  function isNumber(val) {
    val = val + "";
    if (val.length < 1) return false;
    if (isNaN(val)) {
      return false;
    } else {
      return true;
    }
  }

  return (
    <>
      <div
        id="kg_to_lb_calc_tab"
        // className="tab-pane fade active in"
        role="tabpanel"
      >
        <div className="row">
          <div className="col-md-6">
            <h3>Kilogram</h3>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              <strong>Definition</strong>: A kilogram (symbol: kg) is the base
              unit of mass in the International System of Units (SI). It is
              currently defined as being equal to the mass of the International
              Prototype of the Kilogram (IPK), a cylinder made of a
              platinum-iridium alloy.
            </p>
            <div className="clearfix">
              <br />
            </div>
            <h3>Pound</h3>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              <strong>Definition</strong>: A pound (symbol: lb) is a unit of
              mass used in the imperial and US customary systems of measurement.
              The international avoirdupois pound (the common pound used today)
              is defined as exactly 0.45359237 kilograms. The avoirdupois pound
              is equivalent to 16 avoirdupois ounces.
            </p>
          </div>
          <div className="col-md-6">
            <div style={{ width: "100%" }}>
              <div className="body-fat-ratio-heading">
                <h5> kg to lbs</h5>
              </div>

              <div style={{ backgroundColor: "#F7F7F7" }}>
                <div className="clearfix">
                  <br />
                </div>
                <form
                  style={{ width: "95%", marginLeft: "8px", marginTop: "5px" }}
                >
                  <input name="factor" value="0.01" type="hidden" />
                  <div className="row" style={{ borderBottom: "0px" }}>
                    <div className="col-lg-12">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        kilogram
                      </label>
                      <input
                        name="ucfrom"
                        className="ucdcinput form-control"
                        id="ucfrom"
                        onKeyUp={() => ucDCUpdateResult(0)}
                        autoFocus
                        type="text"
                      />
                    </div>
                    <h5 className="text-center my-3">
                      <strong>To</strong>
                    </h5>
                    <div className="col-lg-12">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        pound
                      </label>
                      <input
                        name="ucto"
                        className="ucdcinput form-control"
                        id="ucto"
                        onKeyUp={() => ucDCUpdateResult(1)}
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12">
                      <input
                        name="ucfromunit"
                        id="ucfromunit"
                        value="kilogram [kg] [1.0E+15]"
                        type="hidden"
                      />
                      <input
                        name="uctounit"
                        id="uctounit"
                        value="pound [lbs] [2.2046226218488E+15]"
                        type="hidden"
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <div
                        id="ucresult"
                        className="ucresulttext text-center"
                      ></div>
                    </div>
                  </div>
                </form>
                <script>{"noValidation=0;var processingType=0;"}</script>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WeightConverter;
