import React, { useEffect, useState } from "react";
import ThirdFooter from "../Footer/ThirdFooter";
import Header from "../Header";
import { SendPostRequest } from "../../http";
import { toast } from "react-toastify";
import { capitalize } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Select from "react-select";
import Captcha from "../Captcha/Captcha";
import SecondFooter from "../Footer/SecondFooter";

function DoctorDetail() {
  const sitedata = useSelector((store) => store.global.loginData);
  const patientLogin = useSelector((state) => state.patient.patientLogin);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (patientLogin) {
      setInputValues({
        patient_name: patientLogin?.fullname,
        email: patientLogin?.email,
        contact_number: patientLogin?.contact_num,
      });
    }

    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Hospital Details - ClinTa";

    window.scrollTo(0, 0);
    getHospitalDetail();
    handleRefresh();
  }, []);

  const [hospital_detail, sethospitalDetail] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [gender, setGender] = useState(null);
  const [shift, setShift] = useState(null);

  const todayDate = new Date().toISOString().split("T")[0];

  const [relation, setRelation] = useState(null);
  const [selectedDep, setSelectedDep] = useState(null);

  const [inputValues, setInputValues] = useState({});
  const [hospitalSlots, setHospitalSlots] = useState([]);
  const [displayedValue, setDisplayedValue] = useState(null);
  const [bookingDay, setBookingDay] = useState("");

  const [appointmentisLoading, setAppointmentisLoading] = useState(false);

  const formatDate = (inputDate) => {
    const dateObject = new Date(inputDate);

    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1;
    const day = dateObject.getDate();
    const formattedDate = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;

    return formattedDate;
  };

  // get hospital slots
  useEffect(() => {
    const getHospitalDetail = async () => {
      const date = inputValues.appointment_date;
      const type = sitedata.site_type;
      let allInputs;

      try {
        allInputs = { type, doct_uid: id, date };
        if (date) {
          const res = await SendPostRequest("hospitals/slots", allInputs);
          setHospitalSlots(res.data);
        }
      } catch (error) {
        console.error("Error fetching doctor's slots:", error);
      }
    };
    getHospitalDetail();
  }, [inputValues.appointment_date]);

  const handleContactNumber = (e) => {
    let val = e.target.value;

    if (val.length <= 14) {
      setInputValues((prevValues) => ({
        ...prevValues,
        contact_number: val,
      }));
    }
    return;
  };

  const handleAllInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "appointment_date") {
      const formattedDate = formatDate(value);
      const selectedDate = new Date(formattedDate);
      const dayOfWeek = selectedDate.toLocaleDateString("en-US", {
        weekday: "long",
      }); // Get the day of the week as a string (e.g., "Monday")
      setBookingDay(dayOfWeek);
      setInputValues((prevValues) => ({
        ...prevValues,
        appointment_date: formattedDate,
      }));
    } else {
      setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };
  const [apointmentDays, setApointmentDays] = useState([]);

  const getHospitalDetail = async () => {
    try {
      const response = await SendPostRequest("hospitals/schedule", {
        doct_uid: id,
      });

      if (response.data) {
        const hospitals = response.data.hospitals;
        sethospitalDetail(hospitals);
        setApointmentDays(hospitals[0].schedule);

        if (hospitals.length > 0) {
          setSelectedHospital({
            label: hospitals[0].info.title,
            value: hospitals[0],
          });
        }
      } else {
        if (response.length === 1) {
          sethospitalDetail(response);
          setSelectedHospital({
            label: response[0].info.title,
            value: response[0],
          });
        }
      }
    } catch (error) {
      console.error("Error fetching doctor's schedule:", error);
    }
  };

  const handleHospitalChange = (selectedOption) => {
    setSelectedHospital(selectedOption);
  };

  const reasonOptions = [
    { value: "son-of", label: "Son Of" },
    { value: "daughter-of", label: "Daughter Of" },
    { value: "father-of", label: "Father Of" },
    { value: "mother-of", label: "Mother Of" },
    { value: "husband-of", label: "Husband Of" },
    { value: "wife-of", label: "Wife Of" },
  ];
  const depOptions = [
    { value: "routine-checkup", label: "Routine Checkup" },
    { value: "Operation", label: "Operation" },
    { value: "pathology-test", label: "Pathology Test" },
    { value: "report-checkup", label: "Report Checkup" },
    { value: "other-reason", label: "Other Reason" },
  ];

  const handleDepChange = (selectedOption) => {
    setSelectedDep(selectedOption);
  };

  const hospitalSchedules = {
    CMHHospital: [
      { day: "Monday", time: "09.00-12.00" },
      { day: "Wednesday", time: "15.00-18.00" },
      { day: "Friday", time: "09.00-12.00" },
    ],
    AlShifaHospital: [
      { day: "Monday", time: "10.00-13.00" },
      { day: "Tuesday", time: "14.00-17.00" },
      { day: "Thursday", time: "09.00-12.00" },
    ],
  };
  const hospitalOptions = [
    { value: "AlShifaHospital", label: "AlShifaHospital" },
    { value: "CMHHospital", label: "CMHHospital" },
  ];

  const genderOptions = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ];

  const [shiftOptions, setShiftOptions] = useState([]);

  useEffect(() => {
    const newShiftOptions = [];

    // Check if hospitalSlots is empty
    if (
      Object.keys(hospitalSlots).length === 0 &&
      inputValues.appointment_date
    ) {
      // If empty, set default values
      if (bookingDay !== "") {
        const selectedDayObject = apointmentDays[bookingDay];
        const Morning = "Morning";
        const Evening = "Evening";
        if (selectedDayObject[Morning] && selectedDayObject[Evening]) {
          newShiftOptions.push({ value: "evening", label: "Evening" });
          newShiftOptions.push({ value: "morning", label: "Morning" });
        } else if (selectedDayObject[Morning]) {
          newShiftOptions.push({ value: "morning", label: "Morning" });
        } else if (selectedDayObject[Evening]) {
          newShiftOptions.push({ value: "evening", label: "Evening" });
        }
      }
    } else {
      // If not empty, populate shift options from hospitalSlots
      for (const key in hospitalSlots) {
        if (hospitalSlots.hasOwnProperty(key)) {
          newShiftOptions.push({ value: key, label: hospitalSlots[key] });
        }
      }
    }

    setShiftOptions(newShiftOptions);
    setDisplayedValue(null);
  }, [hospitalSlots]);

  const handleReasonChange = (selectedOption) => {
    setRelation(selectedOption.value);
  };

  const handleGenderChange = (selectedOption) => {
    setGender(selectedOption.value);
  };

  const handleShiftChange = (selectedOption) => {
    setShift(selectedOption.value);
    setDisplayedValue(selectedOption?.label);
  };

  const [captcha, setCaptcha] = useState("");
  const [input_captcha, setInputCaptcha] = useState("");
  const [check_captcha, setCheckCaptcha] = useState("");

  const textColorClass =
    check_captcha === "Captcha Verified" ? "text-success" : "text-danger";

  const handleInputChange = (e) => {
    setInputCaptcha(e.target.value);
  };

  const handleRefresh = () => {
    const newCaptcha = generateRandomCaptcha();
    setCaptcha(newCaptcha);
  };

  const generateRandomCaptcha = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const captchaLength = 6;
    let newCaptcha = "";

    for (let i = 0; i < captchaLength; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      newCaptcha += characters.charAt(randomIndex);
    }

    return newCaptcha;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setAppointmentisLoading(true);

    if (!inputValues.patient_name) {
      toast.error("Enter patient name");
      setAppointmentisLoading(false);
      return;
    } else if (!gender) {
      toast.error("Select Gender");
      setAppointmentisLoading(false);
      return;
    } else if (
      inputValues.email &&
      (!inputValues.email.includes(".") || !inputValues.email.includes("@"))
    ) {
      toast.error("Invalid email");
      setAppointmentisLoading(false);
      return;
    } else if (
      inputValues.contact_number.length > 14 ||
      inputValues.contact_number.length < 10
    ) {
      toast.error("Invalid Number");
      setAppointmentisLoading(false);
      return;
    } else if (!inputValues.contact_number) {
      toast.error("Enter Number");
      setAppointmentisLoading(false);
      return;
    } else if (!inputValues.appointment_date) {
      toast.error("Enter Date");
      setAppointmentisLoading(false);
      return;
    } else if (!shift) {
      toast.error("Select Your Shift");
      setAppointmentisLoading(false);
      return;
    } else if (!input_captcha) {
      toast.error("Enter Captcha");
      setAppointmentisLoading(false);
      return;
    } else if (captcha === input_captcha) {
      setCheckCaptcha("Captcha Verified");
    } else {
      setCheckCaptcha("Captcha failed");
      setAppointmentisLoading(false);
    }

    if (captcha == input_captcha) {
      setCheckCaptcha("Captcha Verified");
      try {
        const myallInputs = {
          ...inputValues,
          gender,
          shift,
          relation,
          doctor_id: id,
        };

        const response = await SendPostRequest(
          "doctors/appointment_form",
          myallInputs
        );

        if (response.status === "success") {
          setInputValues({
            patient_name: patientLogin ? patientLogin.fullname : "",
            email: patientLogin ? patientLogin.email : "",
            contact_number: patientLogin ? patientLogin.contact_num : "",
            age: "",
            relation_name: "",
            appointment_date: "",
            remarks: "",
          });
          setDisplayedValue(null);
          handleRefresh();
          setInputCaptcha("");
          setCheckCaptcha("");
          setRelation({ value: "" });
          setGender({ value: "" });
          setShift({ value: "" });
        }

        toast.success(response.message);
        setAppointmentisLoading(false);
      } catch (error) {
        toast.error("Failed");
        setAppointmentisLoading(false);
      }
    } else {
      setCheckCaptcha("Captcha failed");
      setAppointmentisLoading(false);
    }
  };

  return (
    <>
      <Header />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-center">
              <div className="col-md-12">
                <div className="page-title-inner">
                  {/* <div id="breadcrumb" className="breadcrumb mb-1 mb-lg-2">
                    <a href="index.html" className="theme-color">
                      Home
                    </a>
                    <span className="current">Portfolio Extended</span>
                  </div> */}
                  <h1 className="page-title mb-0">Docotr Detail</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="content-wrapper pad-none">
        <div className="content-inner">
          <section
            id="single-portfolio"
            className="single-portfolio pad-bottom-90 full-dark"
          >
            <div className="container">
              <div className="row relative">
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <div className="title-wrap">
                    <div className="section-title">
                      <h2 className="title text-uppercase mb-0">
                        {hospital_detail[0]?.info
                          ? hospital_detail[0]?.info?.name
                          : "Doctor Detail"}
                        {/* <span className="theme-color">Code 1xed</span> */}
                      </h2>
                      <span className="custom-heading-title mb-0">
                        {hospital_detail[0]?.info?.department}
                      </span>
                      <span className="section-border-bottom"></span>
                    </div>
                  </div>
                  <div className="row blog-list blog-list-style-1 br-style">
                    <div className="blog-inner">
                      <div className="media doctor_detail_img ">
                        <div className="blog-thumb relative">
                          <img
                            // src="/assets/images/blog/blog-list/blog-1.jpg"
                            src={hospital_detail[0]?.info?.profile_pic}
                            className="img-fluid rounded"
                            width="170"
                            height="130"
                            alt="blog-img"
                          />
                        </div>
                        <div className="media-body">
                          <div className="top-meta"></div>
                          <div className="blog-title">
                            <h5 className="fs-18">
                              {hospital_detail[0]?.info?.qualification}
                            </h5>
                          </div>
                          <div className="entry-content">
                            <p className="mb-0">
                              {hospital_detail[0]?.info?.short_description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h4 className="title mb-0 mt-4">Appointment Schedules</h4>

                  <div className="schedules_wrapper">
                    <div className="row">
                      <div className="col-lg-4 col-md-12 col-12">
                        <p className="mb-0">11:00 am to 2:00 pm</p>
                        <p>Monday to Friday</p>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12 d-flex justify-content-center">
                        <div className="schedules_divider"></div>
                      </div>
                      <div className="col-lg-4 col-md-12 col-12 ">
                        <p className="mb-0">7:00 pm to 9:00 pm</p>
                        <p>Friday - Saturday</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <div className="title-wrap">
                    <div className="section-title">
                      <h2 className="title mb-0">Book An Appointment</h2>
                      <span className="section-border-bottom"></span>
                    </div>
                  </div>
                  <form className="row">
                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Patient Name <span className="asterisk">*</span>
                      </label>
                      <input
                        name="patient_name"
                        type="text"
                        className="appointmentform_input"
                        value={inputValues.patient_name}
                        placeholder="Enter Patient Name"
                        onChange={handleAllInputChange}
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Please Select Gender <span className="asterisk">*</span>
                      </label>
                      <Select
                        // name="gender"
                        className="appointmentform_select"
                        value={gender?.value}
                        options={genderOptions}
                        onChange={handleGenderChange}
                        placeholder="Select Gender"
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Please Select Relation
                      </label>
                      <Select
                        className="appointmentform_select"
                        value={relation?.value}
                        options={reasonOptions}
                        onChange={handleReasonChange}
                        placeholder="Select Relation"
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Relation Name
                      </label>
                      <input
                        name="relation_name"
                        value={inputValues.relation_name}
                        type="text"
                        className="appointmentform_input"
                        placeholder="Enter Relation Name"
                        onChange={handleAllInputChange}
                      />
                    </div>

                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Email
                      </label>
                      <input
                        name="email"
                        value={inputValues.email}
                        type="email"
                        className="appointmentform_input"
                        placeholder="Enter Your Email"
                        onChange={handleAllInputChange}
                      />
                    </div>
                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Contact Number <span className="asterisk">*</span>
                      </label>
                      <input
                        name="contact_number"
                        value={inputValues.contact_number}
                        type="text"
                        className="appointmentform_input"
                        placeholder="Enter Contact Number"
                        onChange={handleContactNumber}
                        maxLength={14}
                        minLength={10}
                      />
                    </div>

                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Preferred Date <span className="asterisk">*</span>
                      </label>
                      <div className="cs_with_icon_input">
                        <input
                          name="appointment_date"
                          value={inputValues.appointment_date}
                          type="date"
                          className="appointmentform_input"
                          id="datepicker"
                          placeholder="August 24, 2023"
                          onChange={handleAllInputChange}
                          min={todayDate}
                        />
                      </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Preferred Time <span className="asterisk">*</span>
                      </label>
                      <Select
                        className="appointmentform_select"
                        value={
                          displayedValue !== null
                            ? {
                                value: hospitalSlots?.value,
                                label: displayedValue,
                              }
                            : null
                        }
                        options={shiftOptions}
                        onChange={handleShiftChange}
                        placeholder={
                          inputValues.appointment_date
                            ? "Select Time"
                            : "Please Select Date First"
                        }
                      />
                    </div>

                    <div className="col-lg-6 mb-2">
                      <label className="cs_input_label cs_heading_color">
                        Age
                      </label>
                      <input
                        name="age"
                        value={inputValues.age}
                        type="text"
                        className="appointmentform_input"
                        placeholder="Enter Your Age"
                        onChange={handleAllInputChange}
                      />
                    </div>

                    <div className="col-lg-6 mb-2">
                      <label className="cs_input_label cs_heading_color">
                        Captcha <span className="asterisk">*</span>
                      </label>
                      <Captcha
                        captcha={captcha}
                        inputCaptcha={input_captcha}
                        textColorClass={textColorClass}
                        checkCaptcha={check_captcha}
                        handleInputChange={handleInputChange}
                      />
                    </div>

                    <div className="col-lg-12 mb-3">
                      <label className="cs_input_label cs_heading_color">
                        Remarks
                      </label>
                      <textarea
                        rows={5}
                        name="remarks"
                        type="number"
                        value={inputValues.remarks}
                        className="appointmentform_input pt-3"
                        placeholder="Write Your Remarks Here........."
                        onChange={handleAllInputChange}
                      ></textarea>
                    </div>

                    <div className="col-lg-12 theme_submit_btn_wrapper mb-3">
                      <button
                        className="theme_submit_btn"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={appointmentisLoading}
                      >
                        <span>
                          {appointmentisLoading ? "Please Wait" : "Submit "}
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
{sitedata?.theme_footer === "version_1" ? <SecondFooter /> : <ThirdFooter />}
    </>
  );
}

export default DoctorDetail;
