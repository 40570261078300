import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { setPatientLogin } from "../store/global/actions";
import { capitalize } from "@mui/material";

export default function Login() {
  const sitedata = useSelector((store) => store.global.loginData);
  const dispatch = useDispatch();
  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Login - ClinTa";
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();

  const profileCheck = useSelector((store) => store.global.profileCheck);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [sendMailLoading, setSendMailLoading] = useState(false);
  const [patientisLoading, setPatientisLoading] = useState(false);
  const [hospitalisLoading, setHospitalisLoading] = useState(false);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setHospitalisLoading(true);

    if (username.length === 0) {
      toast.error("Enter Email");
      setHospitalisLoading(false);
      return;
    } else if (!username.includes("@")) {
      toast.error("Invalid Email");
      setHospitalisLoading(false);
      return;
    }
    try {
      const response = await SendPostRequest("auth/login", {
        login_email: username,
        login_password: password,
        // login_type: sitedata?.site_type,
      });
      setUsername([]);
      setPassword([]);

      if (response.data) {
        if (response.status === "success") {
          const redirectUrl = response?.data.url;
          if (redirectUrl) {
            window.open(redirectUrl, "_blank");
          }

          toast.success("You are logged in");
        } else {
          setHospitalisLoading(false);
          toast.error("User Not Found");
        }
      } else {
        if (response.errorCode === "INVALID_CREDENTIALS") {
          toast.error("Incorrect username or password");
          setHospitalisLoading(false);
        } else {
          toast.error("Login failed. Please check your credentials.");
          setHospitalisLoading(false);
        }
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      console.error("Login failed:", error);
      setHospitalisLoading(false);
    } finally {
      setHospitalisLoading(false);
    }
  };

  const [contactNum, setContactNum] = useState("");
  const [patientPassword, setpatientPassword] = useState("");
  const handleUserContactChange = (e) => {
    setContactNum(e.target.value);
  };

  const handlePatientPasswordChange = (e) => {
    setpatientPassword(e.target.value);
  };

  const handlePatientLogin = async (e) => {
    e.preventDefault();
    setPatientisLoading(true);

    if (contactNum.length === 0) {
      toast.error("Enter Email / Phone Number");
      setPatientisLoading(false);
      return;
    } else if (patientPassword.length === 0) {
      toast.error("Enter your Password");
      setPatientisLoading(false);
      return;
    }

    try {
      const response = await SendPostRequest("auth/patient_login", {
        contactnumber: contactNum,
        password: patientPassword,
        // login_type: sitedata?.site_type,
      });
      setContactNum([]);
      setpatientPassword([]);

      if (response.data) {
        if (response.status === "success") {
          dispatch(setPatientLogin(response?.data));
          toast.success("Patient logged in");
          navigate("/patient");
        } else {
          toast.error("User not found");
          setPatientisLoading(false);
        }
      } else {
        if (response.errorCode === "INVALID_CREDENTIALS") {
          toast.error("Incorrect username or password");
          setPatientisLoading(false);
        } else {
          toast.error("Login failed. Please check your credentials.");
          setPatientisLoading(false);
        }
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      console.error("Login failed:", error);
      setPatientisLoading(false);
    }
  };

  const [forgotEmail, setForgotEmail] = useState("");

  const handleChangeForgotEmail = (e) => {
    setForgotEmail(e.target.value);
  };

  const handleClickForgotEmail = async (e) => {
    if (forgotEmail === "") {
      toast.error("Enter Email");
      setSendMailLoading(false);
      return;
    } else if (!forgotEmail.includes("@")) {
      toast.error("Enter Valid Email");
      setSendMailLoading(false);
      return;
    }
    setSendMailLoading(true);

    // e.preventDefault();

    try {
      const response = await SendPostRequest("auth/forget_password", {
        action: "send_email",
        email: forgotEmail,
      });
      if (response?.status === "success") {
        toast.success(response?.message);
        setForgotEmail("");
        setIsOpen(false);
      } else {
        toast.error(response?.message);
        setSendMailLoading(false);
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      setSendMailLoading(false);
      console.error("Login failed:", error);
    }
  };

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -90%)",
      width: "35%",
      border: "1px solid #307bc4  ",
      borderRadius: "20px",
    },
  };

  return (
    <>
      <ToastContainer />
      <Header />

      <section>
        <div className="cs_height_42 cs_height_xl_25"></div>

        <div className="cs_height_42 cs_height_xl_25"></div>
        <div className="row">
          <div className="container col-lg-6">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
                  <div className="row">
                    {sitedata.site_type == "hospitals" ? (
                      <h2>Hospital Login</h2>
                    ) : (
                      <h2>Doctor Login</h2>
                    )}

                    <div className="col-lg-12 pl-0">
                      <label className="cs_input_label cs_heading_color">
                        Email <span className="asterisk">*</span>
                      </label>
                      <input
                        type="email"
                        className="cs_form_field"
                        placeholder="Enter your Email"
                        value={username}
                        onChange={handleUsernameChange}
                        required
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-12 pl-0">
                      <div className="forget-password">
                        <label className="cs_input_label cs_heading_color">
                          Password <span className="asterisk">*</span>
                        </label>
                        <Link>Forget Password</Link>
                      </div>
                      <input
                        type="password"
                        className="cs_form_field"
                        placeholder="Enter Your Password"
                        value={password}
                        onChange={handlePasswordChange}
                        required
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-12 ps-0">
                      {profileCheck?.banners === 0 && (
                        <div
                          class="alert alert-danger text-center p-2 font-weight-bold"
                          role="alert"
                        >
                          Website Under Construction, please update website
                          banners.
                        </div>
                      )}
                      {profileCheck?.banners !== 0 &&
                        profileCheck?.services === 0 && (
                          <div
                            class="alert alert-danger text-center p-2 font-weight-bold"
                            role="alert"
                          >
                            Website Under Construction, please update your
                            services.
                          </div>
                        )}

                      {profileCheck?.banners !== 0 &&
                        profileCheck?.services !== 0 &&
                        profileCheck?.doctors === 0 && (
                          <div
                            class="alert alert-danger text-center p-2 font-weight-bold"
                            role="alert"
                          >
                            Website Under Construction, please update your
                            schedule for appointment.
                          </div>
                        )}
                    </div>
                    <div className="col-lg-12 p-0">
                      <div className="cs_height_18"></div>
                      <button
                        className="cs_btn cs_style_1"
                        type="submit"
                        onClick={handleLogin}
                        disabled={hospitalisLoading}
                      >
                        <span>
                          {hospitalisLoading ? "Please Wait" : "Login"}
                        </span>
                        <i>
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {sitedata?.my_account?.patientportal === true && (
            <div className="container col-lg-6">
              <div className="row">
                <div className="col-lg-10 offset-lg-1">
                  <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
                    <div className="row">
                      <h2>Patient Login</h2>
                      <div className="col-lg-12 pl-0">
                        <label className="cs_input_label cs_heading_color">
                          Email / Mobile Number{" "}
                          <span className="asterisk">*</span>
                        </label>
                        <input
                          type="text"
                          className="cs_form_field"
                          placeholder="Enter Your Email/Phone Number"
                          value={contactNum}
                          onChange={handleUserContactChange}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>
                      <div className="col-lg-12 pl-0">
                        <div className="forget-password">
                          <label className="cs_input_label cs_heading_color">
                            Password <span className="asterisk">*</span>
                          </label>
                          <Link onClick={openModal}>Forget Password</Link>

                          <Modal
                            isOpen={modalIsOpen}
                            onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            className="forget-pass-modal"
                            contentLabel="Example Modal"
                          >
                            <div className="forget-password-modal">
                              <h2 ref={(_subtitle) => (subtitle = _subtitle)}>
                                Password Recovery
                              </h2>
                              <label className="cs_input_label cs_heading_color">
                                Enter your email
                              </label>
                              <input
                                type="email"
                                className="cs_form_field"
                                placeholder="Enter Your Email"
                                value={forgotEmail}
                                onChange={handleChangeForgotEmail}
                              />
                              <button
                                onClick={handleClickForgotEmail}
                                disabled={sendMailLoading}
                              >
                                {sendMailLoading ? "Please Wait" : "Send Mail"}
                              </button>
                              <button onClick={closeModal}>Close</button>
                            </div>
                          </Modal>
                        </div>

                        <input
                          type="password"
                          className="cs_form_field"
                          placeholder="Enter Your Password"
                          value={patientPassword}
                          onChange={handlePatientPasswordChange}
                        />
                        <div className="cs_height_42 cs_height_xl_25"></div>
                      </div>

                      <div className="col-lg-12 ps-0">
                        <div className="cs_height_18"></div>
                        <button
                          type="button"
                          onClick={handlePatientLogin}
                          className="cs_btn cs_style_1"
                          disabled={patientisLoading}
                        >
                          <span>
                            {patientisLoading ? "Please Wait" : "Login "}
                          </span>
                          <i>
                            <img
                              src="/assets/img/icons/arrow_white.svg"
                              alt="Icon"
                            />
                            <img
                              src="/assets/img/icons/arrow_white.svg"
                              alt="Icon"
                            />
                          </i>
                        </button>
                        <Link to="/signup">
                          <button
                            type="button"
                            className="cs_btn cs_style_1 float-right"
                          >
                            <span>Signup</span>
                            <i>
                              <img
                                src="/assets/img/icons/arrow_white.svg"
                                alt="Icon"
                              />
                              <img
                                src="/assets/img/icons/arrow_white.svg"
                                alt="Icon"
                              />
                            </i>
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <div className="cs_height_77 cs_height_xl_77"></div>
      <Footer />
    </>
  );
}
