import React, { useEffect, useState } from "react";
import Header from "../Header";
import ThirdFooter from "../Footer/ThirdFooter";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDate } from "../../Helper/Helper";
import SecondFooter from "../Footer/SecondFooter";

function NewsListing() {
  const [newsData, setNewsData] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    getNewsData();
    window.scrollTo(0, 0);
  }, []);

  const getNewsData = async () => {
    const response = await SendPostRequest("news/list");
    setNewsData(response?.data);
  };

  return (
    <>
      <Header />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">News and Updates</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-section pad-top-80 pad-bottom-80 full-dark">
        <div className="container">
        {newsData?.length > 0 ? (
          <div className="row">
            <div className="col-md-8">
              <div
                className="blog-main-wrapper blog-style-6 margin-bottom-30"
                data-animation="fadeInDown"
              >
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src={newsData[0]?.image}
                      className="img-fluid"
                      style={{ height: "460px" }}
                      width="750"
                      //   height="450"
                      alt="blog-img"
                    />
                    <div className="blog-details typo-white pt-0">
                      <div className="top-meta mb-1">
                        <ul className="top-meta-list">
                          <li>
                            <div className="post-date">
                              <a href="#">
                                <i className="ti-calendar theme-color me-1"></i>{" "}
                                {formatDate(newsData[0]?.date)}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="blog-title">
                        <h5 className="margin-bottom-5">
                          <Link
                            to={`/news/detail/${newsData[0]?.slug}`}
                            className="blog-name"
                          >
                            {newsData[0]?.title}
                          </Link>
                        </h5>
                      </div>
                      <div className="blog-link">
                        <Link
                          to={`/news/detail/${newsData[0]?.slug}`}
                          className="link font-w-500"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {newsData?.slice(1, newsData?.length)?.map((data, index) => (
              <div key={index} className="col-md-4" data-animation="fadeInDown">
                <div className="blog-main-wrapper blog-style-1">
                  <div className="blog-inner" style={{ height: "460px" }}>
                    <div className="blog-thumb relative">
                      <img
                        src={data?.image}
                        className="img-fluid"
                        width="768"
                        height="600"
                        alt="blog-img"
                      />
                      <div className="top-meta">
                        <ul className="top-meta-list">
                          <li>
                            <div className="post-date">
                              <a>
                                <i className="ti-calendar"></i>
                                {formatDate(data?.date)}
                              </a>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog-details pt-0">
                      <div className="blog-title">
                        <h4 className="margin-bottom-10">
                          <Link
                            to={`/news/detail/${data?.slug}`}
                            className="blog-name"
                          >
                            {data?.title}
                          </Link>
                        </h4>
                      </div>
                      <div className="post-desc mt-2">
                        <p>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry.
                        </p>
                        <div className="blog-link">
                          <Link
                            to={`/news/detail/${data?.slug}`}
                            className="link font-w-500"
                          >
                            Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="col-12 text-center">
              <h4>No Data Found</h4>
            </div>
        )}

          {/* <div className="row">
            <div className="col-md-4" data-animation="fadeInDown">
              <div className="blog-main-wrapper blog-style-5">
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src="/assets/images/blog/blog-grid/blog-grid1.jpg"
                      className="img-fluid"
                      width="768"
                      height="600"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-details pt-0">
                    <div className="top-meta mb-1">
                      <ul className="top-meta-list">
                        <li>
                          <div className="post-date">
                            <a href="#">
                              <i className="ti-calendar theme-color me-1"></i>{" "}
                              Oct 21, 2019
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-title">
                      <h5 className="margin-bottom-5">
                        <a
                          href="blog-single-right-sidebar.html"
                          className="blog-name"
                        >
                          A Successful House Build
                        </a>
                      </h5>
                    </div>
                    <div className="post-desc mt-2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <div className="blog-link">
                        <a
                          target="_blank"
                          href="blog-single-right-sidebar.html"
                          className="link font-w-500"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-animation="fadeInDown">
              <div className="blog-main-wrapper blog-style-5">
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src="/assets/images/blog/blog-grid/blog-grid1.jpg"
                      className="img-fluid"
                      width="768"
                      height="600"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-details pt-0">
                    <div className="top-meta mb-1">
                      <ul className="top-meta-list">
                        <li>
                          <div className="post-date">
                            <a href="#">
                              <i className="ti-calendar theme-color me-1"></i>{" "}
                              Oct 21, 2019
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-title">
                      <h5 className="margin-bottom-5">
                        <a
                          href="blog-single-right-sidebar.html"
                          className="blog-name"
                        >
                          A Successful House Build
                        </a>
                      </h5>
                    </div>
                    <div className="post-desc mt-2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <div className="blog-link">
                        <a
                          target="_blank"
                          href="blog-single-right-sidebar.html"
                          className="link font-w-500"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4" data-animation="fadeInDown">
              <div className="blog-main-wrapper blog-style-5">
                <div className="blog-inner">
                  <div className="blog-thumb relative mb-4">
                    <img
                      src="/assets/images/blog/blog-grid/blog-grid1.jpg"
                      className="img-fluid"
                      width="768"
                      height="600"
                      alt="blog-img"
                    />
                  </div>
                  <div className="blog-details pt-0">
                    <div className="top-meta mb-1">
                      <ul className="top-meta-list">
                        <li>
                          <div className="post-date">
                            <a href="#">
                              <i className="ti-calendar theme-color me-1"></i>{" "}
                              Oct 21, 2019
                            </a>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-title">
                      <h5 className="margin-bottom-5">
                        <a
                          href="blog-single-right-sidebar.html"
                          className="blog-name"
                        >
                          A Successful House Build
                        </a>
                      </h5>
                    </div>
                    <div className="post-desc mt-2">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry.
                      </p>
                      <div className="blog-link">
                        <a
                          target="_blank"
                          href="blog-single-right-sidebar.html"
                          className="link font-w-500"
                        >
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      {sitedata?.theme_footer === "version_1" ? <SecondFooter /> : <ThirdFooter />}
    </>
  );
}

export default NewsListing;
