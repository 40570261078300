import React from "react";

function Bmi() {
  function BMICalculate() {
    var w = document.bmi.weight.value * 1;
    w = w * 2.2;

    var HeightFeetInt = document.bmi.htf.value * 1;
    var HeightInchesInt = document.bmi.hti.value * 1;

    var HeightFeetConvert = HeightFeetInt * 12;
    var h = HeightFeetConvert + HeightInchesInt;
    var displaybmi = Math.round((w * 703) / (h * h));
    var rvalue = true;
    function mod(a, b) {
      return a % b;
    }
    if (w <= 35 || w >= 500 || h <= 48 || h >= 120) {
      alert("Invalid data.  Please check and re-enter!");
      rvalue = false;
    }
    if (rvalue) {
      if (HeightInchesInt > 11) {
        var reminderinches = mod(HeightInchesInt, 12);
        document.bmi.hti.value = reminderinches;
        document.bmi.htf.value =
          HeightFeetInt + (HeightInchesInt - reminderinches) / 12;
        document.bmi.answer.value = displaybmi;
      }
      if (displaybmi < 19) document.bmi.comment.value = "Underweight";
      if (displaybmi >= 19 && displaybmi <= 25)
        document.bmi.comment.value = "Desirable";
      if (displaybmi >= 26 && displaybmi <= 29)
        document.bmi.comment.value = "prone to health risks";
      if (displaybmi >= 30 && displaybmi <= 40)
        document.bmi.comment.value = "Obese";
      if (displaybmi > 40) document.bmi.comment.value = "Extremely obese";

      document.bmi.answer.value = displaybmi;
    }
    return rvalue;
  }
  return (
    <>
      <div className="bmi">
        <div id="bmi_tab">
          <div className="row">
            <div className="col-md-6">
              <h3>What is BMI?</h3>
              <p style={{ fontSize: 16, textAlign: "justify" }}>
                BMI or Body mass index is a measure of body fat based on height
                and weight that applies to both adult men and women. Calculating
                the BMI is one of the best methods for the population assessment
                of overweight and obesity. It is low-cost and easy to use for
                clinicians and the general public. The use of BMI allows people
                to compare their own weight status to the general population.
                The only information required to calculate a person's BMI are
                height, weight, and the BMI formula. Any person with a BMI over
                25 would be classified as overweight.
              </p>
              <div className="clearfix">
                <br />
              </div>
              <h3>BMI Chart</h3>
              <table className="table table-responsive table-bordered">
                <thead>
                  <tr style={{ backgroundColor: "#37A3EC" }}>
                    <th>Age</th>
                    <th>Recommended</th>
                    <th>Overweight</th>
                    <th>Obese</th>
                    <th>Ext-Obese</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>under 17 </td>
                    <td>15-20 </td>
                    <td>21+ </td>
                    <td>26+ </td>
                    <td>34+ </td>
                  </tr>
                  <tr>
                    <td>under 35 </td>
                    <td>18-24 </td>
                    <td>25+ </td>
                    <td>30+ </td>
                    <td>40+ </td>
                  </tr>
                  <tr>
                    <td>35 and over </td>
                    <td>19-26 </td>
                    <td>27+ </td>
                    <td>30+ </td>
                    <td>40+ </td>
                  </tr>
                  <tr>
                    <td colSpan="5">
                      BMI values below 19 for men and 18 for women indicate an
                      underweight condition.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="col-md-6">
              <div style={{ width: "100%" }}>
                <div className="body-fat-ratio-heading">
                  <h5>Body Mass Index BMI Calculator</h5>
                </div>

                <div style={{ backgroundColor: "#F7F7F7" }}>
                  <div className="clearfix">
                    <br />
                  </div>
                  <form
                    style={{
                      width: "95%",
                      marginLeft: "15px",
                      marginTop: "20px",
                    }}
                    id="bmi"
                    name="bmi"
                  >
                    <div className="row" style={{ borderBottom: "0px" }}>
                      <div className="col-lg-12">
                        <label
                          className="control-label mb-10"
                          htmlFor="exampleInputuname_1"
                        >
                          Weight In Kg
                        </label>
                        <input
                          className="form-control"
                          id="weight"
                          name="weight"
                          placeholder="Weight"
                          type="text"
                        />
                      </div>
                      <div className="col-lg-12 my-3">
                        <h5>Height</h5>
                        <hr
                          style={{
                            marginBottom: "-8px",
                            borderTop: "0.2px solid lightgray",
                          }}
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="control-label mb-10"
                          htmlFor="exampleInputuname_1"
                        >
                          Feet
                        </label>
                        <input
                          className="form-control"
                          id="feets"
                          name="htf"
                          placeholder="Feets"
                          type="text"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          className="control-label mb-10"
                          htmlFor="exampleInputuname_1"
                        >
                          Inches
                        </label>
                        <input
                          className="form-control"
                          id="inches"
                          name="hti"
                          placeholder="Inches"
                          type="text"
                        />
                      </div>
                      <div className="col-lg-12 my-4">
                        <button
                          // style={{ marginLeft: "180px" }}
                          type="button"
                          onClick={BMICalculate}
                          className=""
                        >
                          Calculate BMI
                        </button>
                      </div>
                      <div className="col-lg-12 mb-3">
                        <label
                          className="control-label mb-10"
                          htmlFor="exampleInputuname_1"
                        >
                          Body Mass Index (BMI)
                        </label>
                        <input
                          className="form-control"
                          id="bodyfat"
                          name="answer"
                          placeholder="Result"
                          type="text"
                        />
                      </div>
                      <div className="col-lg-12 mb-4">
                        According to the Panel on Energy, Obesity, and Body
                        Weight Standards published by American Journal of
                        Clinical Nutrition, your category is:
                        <div className="clearfix">
                          <br />
                        </div>
                        <div>
                          <input
                            className="form-control"
                            id="bodyfat"
                            name="comment"
                            placeholder="Comment"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          <strong>1 Pound(lbs)</strong>=<strong>0.45 Kg</strong>
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          <strong>1 kg</strong>=
                          <strong>2.2 Pounds (lbs)</strong>{" "}
                        </p>
                      </div>
                      <div className="col-lg-12">
                        <p>
                          <strong>1 Feet</strong>=<strong>0.304 meters</strong>
                        </p>
                      </div>
                      <div className="clearfix">
                        <br />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bmi;
