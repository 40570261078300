import React, { useState } from "react";

function Edd() {
  const [menstrual, setMenstrual] = useState("");
  const [cycle, setCycle] = useState("");
  const [luteal, setLuteal] = useState("");
  const [conception, setConception] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [fetalAge, setFetalAge] = useState("");

  function pregnancyCalc() {
    const menstrualInput = new Date(menstrual);
    const cycleValue = cycle || 28;
    const lutealValue = luteal || 14;

    if (cycleValue < 22 || cycleValue > 45) {
      alert("Your cycle length is either too short or too long...");
      return;
    }

    if (lutealValue < 9 || lutealValue > 16) {
      alert("Your luteal phase length is either too short or too long...");
      return;
    }

    const ovulation = new Date(
      menstrualInput.getTime() + cycleValue * 86400000 - lutealValue * 86400000
    );

    setConception(dispDate(ovulation));

    const duedate = new Date(ovulation.getTime() + 266 * 86400000);
    setDueDate(dispDate(duedate));

    const fetalage = 14 + 266 - (duedate - new Date()) / 86400000;
    const weeks = parseInt(fetalage / 7);
    const days = Math.floor(fetalage % 7);

    setFetalAge(`${weeks} week${weeks > 1 ? "s" : ""}, ${days} days`);
  }
  function dispDate(dateObj) {
    var month = dateObj.getMonth() + 1;
    month = month < 10 ? "0" + month : month;

    var day = dateObj.getDate();
    day = day < 10 ? "0" + day : day;

    var year = dateObj.getYear();
    if (year < 2000) year += 1900;

    return month + "/" + day + "/" + year;
  }
  return (
    <>
      <div id="pregnancy_due_date_calc" role="tabpanel">
        <div className="row">
          <div className="col-md-6">
            <h3>Expected Date of Delivery (EDD)</h3>
            <p style={{ fontSize: "16px", textAlign: "justify" }}>
              Calculation of the pregnancy due date or the expected date of
              delivery (EDD) of your baby is possible by using this unique
              pregnancy due date calculator. Just enter the 1st day of your last
              menstrual period below, and you can find the expected date of
              delivery or your due date. Pregnancy, on average, lasts 267 days
              (38 weeks) from conception. If you positively know your conception
              date, you can add 267 days to it for your due date. A simple
              formula for the calculation of your due date is also provided
              after this pregnancy due date calculator.
            </p>
            <div className="clearfix">
              <br />
            </div>
            <h3>Due Date Calculator</h3>
            <p style={{ fontSize: "16px", textAlign: "justify" }}>
              Pregnancy, on average, lasts 267 days (38 weeks) from conception.
              If you positively know your conception date, you can add 267
              calendar days to it to calculate your pregnancy due date.
            </p>
            <p>The following formulas can also tell your Expected Due Date :</p>
            <p>
              <strong>
                Date of the first day of your last period + 281 days = due date
              </strong>
            </p>
            <p>or</p>
            <p>
              <strong>
                Date of the first day of your last period + 7 days - 3 months =
                due date
              </strong>
            </p>
          </div>
          <div className="col-md-6">
            <div style={{ width: "100%" }}>
              <div className="body-fat-ratio-heading">
                <h5> Due Date Calculation</h5>
              </div>

              <div style={{ backgroundColor: "#F7F7F7" }}>
                <div className="clearfix">
                  <br />
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    pregnancyCalc();
                  }}
                  id="pregnancy_due_date_form"
                  style={{
                    width: "95%",
                    marginLeft: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="row" style={{ borderBottom: "0px" }}>
                    <div className="col-lg-12 mb-3">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Last Menstrual Period:
                      </label>
                      <input
                        type="date"
                        name="menstrual"
                        id="menstrual"
                        className="form-control"
                        size="10"
                        maxLength="10"
                        value={menstrual}
                        onChange={(e) => setMenstrual(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Average Length of Cycles:{" "}
                      </label>
                      <label>(22 to 45)</label>
                      <input
                        type="text"
                        name="cycle"
                        id="cycle"
                        value={cycle}
                        size="3"
                        maxLength="3"
                        className="form-control"
                        onChange={(e) => setCycle(e.target.value)}
                      />
                      <label> (defaults to 28)</label>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label
                        className="control-label"
                        htmlFor="exampleInputuname_1"
                      >
                        Average Luteal Phase Length:
                      </label>
                      <label>(9 to 16)</label>
                      <input
                        type="text"
                        name="luteal"
                        id="luteal"
                        value={luteal}
                        size="3"
                        maxLength="3"
                        className="form-control"
                        onChange={(e) => setLuteal(e.target.value)}
                      />
                      <label>(defaults to 14)</label>
                    </div>
                    <div className="col-lg-12 mb-5">
                      <button type="submit" className="">
                        Calculate
                      </button>
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Estimated Conception:
                      </label>
                      <input
                        type="text"
                        name="conception"
                        id="conception"
                        value={conception}
                        size="20"
                        className="form-control"
                        readOnly
                      />
                    </div>
                    <div className="col-lg-12 mb-3">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Estimated Due Date:
                      </label>
                      <input
                        type="text"
                        name="duedate"
                        id="duedate"
                        value={dueDate}
                        size="20"
                        className="form-control"
                        readOnly
                      />
                    </div>
                    <div className="col-lg-12">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Estimated Fetal Age:
                      </label>
                      <input
                        type="text"
                        name="fetalage"
                        id="fetalage"
                        value={fetalAge}
                        size="20"
                        className="form-control"
                        readOnly
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Edd;
