import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { Link } from "react-router-dom";
import { formatDate } from "../../Helper/Helper";
import Spinner from "../Spinner";

export default function Careers() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [backgroundImage, setBackgroundImage] = useState("");
  const [careersData, setCareersData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    }
    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getCareerData();

    // const date = "2024-07-16 00:00:00";
  }, []);

  function isFutureDate(dateString) {
    const inputDate = new Date(dateString);
    const currentDate = new Date();

    currentDate.setHours(0, 0, 0, 0);

    return inputDate > currentDate;
  }

  const getCareerData = async () => {
    const response = await SendPostRequest("careers/jobs");
    setCareersData(response?.data);
    setLoading(false);
  };

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Careers</h2>
          </div>
        </div>
      </section>

      <div className="cs_height_50 cs_height_lg_50"></div>
      {careersData?.page_description && (
        <div className="container">
          <div className="cs_banner_text">
            <h2 className=" cs_fs_52 text-center">Join Our Dynamic Teams</h2>
            <p
              className="cs_banner_subtitle cs_fs_12 mb-0 "
              style={{ textAlign: "center" }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: careersData?.page_description,
                }}
              />
            </p>
          </div>
        </div>
      )}

      <div className="cs_height_105"></div>
      <section>
        <div className="container" id="jobs">
          <div className="section-title text-center">
            <h2>Available Positions</h2>
            <p>Offer a wide range of services to help businesses establish.</p>
          </div>
          <div className="career-position my-5">
            {loading ? (
              <div className="text-center">
                <Spinner />
              </div>
            ) : careersData?.jobs?.length > 0 ? (
              careersData?.jobs.map((data, index) => (
                <div key={index} className="row align-items-center mb-4">
                  <div className="col-lg-4 col-sm-12">
                    <span>Job Title</span>
                    <h3>{data?.title}</h3>
                  </div>
                  <div className="col-lg-6 col-sm-12">
                    <ul className="row m-0">
                      <div className="col-lg-6 col-sm-12">
                        <li>Experience: {data?.experience || "--"}</li>
                        <li>Deadline: {formatDate(data?.dead_line)}</li>
                      </div>
                      <div className="col-lg-6 col-sm-12">
                        <li>{data?.job_type || "--"}</li>
                        {/* <li>On site</li> */}
                      </div>
                    </ul>
                  </div>
                  <div className="col-lg-2 col-sm-12">
                    {isFutureDate(data?.published_at) ? (
                      // "Coming Soon"
                      <button className="coming-soon-btn">Coming Soon</button>
                    ) : (
                      <Link to={`/career-detail/${data?.uid}`}>
                        <button className="career-apply-btn">
                          Apply Position
                        </button>
                      </Link>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center">
                <p>
                  No available positions at the moment. Please check back later.
                </p>
              </div>
            )}
          </div>
        </div>
      </section>

      <div className="cs_height_105"></div>
      <Footer />
    </>
  );
}
