import React from "react";
import {  Dna } from "react-loader-spinner";

const Spinner = () => {
  return (
    <>
      <Dna
        height={200}
        width={200}
        radius={5}
        color="#4fa94d"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        // wrapperStyle=""
        visible={true}
      />
    </>
  );
};

export default Spinner;
