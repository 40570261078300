import React, { useEffect, useState } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { SendPostRequest } from "../../http";
import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import { capitalize } from "@mui/material";

export default function AboutMe() {
  const [doctorAbout, setDoctorAbout] = useState([]);
  const [activeAwardIndex, setActiveAwardIndex] = useState(null);
  const [activeEducationIndex, setActiveEducationIndex] = useState(null);
  const [activePostGraduationIndex, setActivePostGraduationIndex] =
    useState(null);
  const [activeExperienceIndex, setActiveExperienceIndex] = useState(null);
  const sitedata = useSelector((store) => store.global.loginData);

  const toggleAccordion = (section, index) => {
    switch (section) {
      case "award":
        setActiveAwardIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "education":
        setActiveEducationIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "postgraduation":
        setActivePostGraduationIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "experience":
        setActiveExperienceIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: About - ClinTa";
    window.scrollTo(0, 0);
    getDoctorAboutData();
  }, []);

  const getDoctorAboutData = async () => {
    const response = await SendPostRequest("content/about");
    setDoctorAbout(response.data);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if(sitedata){
       themeColor = sitedata?.theme_color?.split(".css").join("");
    }else{}
    
    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {" "}
        {/* <div className="cs_banner_img">
          <img
            src={`data:image/jpeg;base64, ${doctorAbout?.image}`}
            alt="Banner"
          />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">
              Dr. {doctorAbout?.name}
            </h2>
          </div>
        </div>
      </section>
      {doctorAbout.length === 0 ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner />
        </div>
      ) : (
        <section>
          <div className="container">
            <div className="row ">
              <div className="col-lg-6">
                <div className="cs_height_40 cs_height_lg_0"></div>
                <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0  cs_fs_38 mb-2 mt-5 ">
                  About ME
                </h3>
                <p
                  className="cs_banner_subtitle cs_fs_12 mb-0 cs_heading_color"
                  style={{ textAlign: "justify", lineHeight: "35px" }}
                >
                  {doctorAbout?.short_description
                    ? doctorAbout?.short_description
                    : "Coming soon! Awaited content to be revealed shortly."}
                </p>

                <div>
                  <h3
                    className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold m-0 cs_accent_color cs_fs_20 mt-3 pl-0"
                    style={{ textAlign: "" }}
                  >
                    AWARDS AND MEMBERSHIPS
                  </h3>
                  <div className="cs_height_5"></div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="cs_section_heading cs_style_1">
                        <div className="cs_height_5"></div>
                        <div className="cs_accordians cs_style1 cs_heading_color">
                          {!doctorAbout.award_membership.length < 1 ? (
                            doctorAbout.award_membership &&
                            doctorAbout.award_membership.map((item, index) => (
                              <div
                                className={`cs_accordian ${
                                  index === activeAwardIndex ? "active" : ""
                                }`}
                                key={index}
                              >
                                <h2
                                  className="cs_accordian_head cs_heading_color"
                                  onClick={() =>
                                    toggleAccordion("award", index)
                                  }
                                >
                                  {item?.Title}
                                  <span className="cs_accordian_arrow">
                                    <svg
                                      width="28"
                                      height="28"
                                      viewBox="0 0 28 28"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M-6.11959e-07 14C-2.74531e-07 21.7195 6.28053 28 14 28C21.7195 28 28 21.7195 28 14C28 6.28053 21.7195 -9.49388e-07 14 -6.11959e-07C6.28053 -2.74531e-07 -9.49388e-07 6.28053 -6.11959e-07 14ZM26.25 14C26.25 20.7548 20.7548 26.25 14 26.25C7.24522 26.25 1.75 20.7548 1.75 14C1.75 7.24522 7.24522 1.75 14 1.75C20.7548 1.75 26.25 7.24522 26.25 14ZM13.3814 8.13137C13.7233 7.78947 14.2769 7.78947 14.6186 8.13137L18.9936 12.5064C19.1645 12.6772 19.25 12.9012 19.25 13.125C19.25 13.3488 19.1645 13.5728 18.9936 13.7436C18.6517 14.0855 18.0981 14.0855 17.7564 13.7436L14.875 10.8622L14.875 19.25C14.875 19.7332 14.4837 20.125 14 20.125C13.5163 20.125 13.125 19.7332 13.125 19.25L13.125 10.8622L10.2436 13.7436C9.90172 14.0855 9.34806 14.0855 9.00637 13.7436C8.66469 13.4017 8.66447 12.8481 9.00637 12.5064L13.3814 8.13137Z"
                                        fill="#307BC4"
                                      />
                                    </svg>
                                  </span>
                                </h2>
                                <div
                                  className="cs_accordian_body"
                                  style={{
                                    display:
                                      index === activeAwardIndex
                                        ? "block"
                                        : "none",
                                  }}
                                >
                                  <div className="doctor-gratuation-content">
                                    <h3 className="">Year</h3>
                                    <p>{item?.Year}</p>
                                  </div>
                                  <p>{item?.Description}</p>
                                </div>
                              </div>
                            ))
                          ) : (
                            <p>Coming soon! Awaited content to be revealed shortly.</p>
                          )}
                        </div>
                      </div>
                      <div className="cs_height_5"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold  cs_accent_color cs_fs_38   about-us-heading mb-0">
                  Education
                </h3>
                <div className="cs_section_heading cs_style_1">
                  <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold  cs_accent_color cs_fs_20 mb-0 graduaton-heading ">
                    Graduation
                  </h3>
                  <div className="cs_height_5"></div>
                  <div className="cs_accordians cs_style1 cs_heading_color">

                    { doctorAbout?.graduation.length < 1 ? (<p>Coming soon! Awaited content to be revealed shortly.</p>):(doctorAbout?.graduation &&
                      doctorAbout?.graduation.map((item, index) => (
                        <div
                          className={`cs_accordian ${
                            index === activeEducationIndex ? "active" : ""
                          }`}
                          key={index}
                        >
                          <h2
                            className="cs_accordian_head cs_heading_color"
                            onClick={() => toggleAccordion("education", index)}
                          >
                            {item?.Title}
                            <span className="cs_accordian_arrow">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M-6.11959e-07 14C-2.74531e-07 21.7195 6.28053 28 14 28C21.7195 28 28 21.7195 28 14C28 6.28053 21.7195 -9.49388e-07 14 -6.11959e-07C6.28053 -2.74531e-07 -9.49388e-07 6.28053 -6.11959e-07 14ZM26.25 14C26.25 20.7548 20.7548 26.25 14 26.25C7.24522 26.25 1.75 20.7548 1.75 14C1.75 7.24522 7.24522 1.75 14 1.75C20.7548 1.75 26.25 7.24522 26.25 14ZM13.3814 8.13137C13.7233 7.78947 14.2769 7.78947 14.6186 8.13137L18.9936 12.5064C19.1645 12.6772 19.25 12.9012 19.25 13.125C19.25 13.3488 19.1645 13.5728 18.9936 13.7436C18.6517 14.0855 18.0981 14.0855 17.7564 13.7436L14.875 10.8622L14.875 19.25C14.875 19.7332 14.4837 20.125 14 20.125C13.5163 20.125 13.125 19.7332 13.125 19.25L13.125 10.8622L10.2436 13.7436C9.90172 14.0855 9.34806 14.0855 9.00637 13.7436C8.66469 13.4017 8.66447 12.8481 9.00637 12.5064L13.3814 8.13137Z"
                                  fill="#307BC4"
                                />
                              </svg>
                            </span>
                          </h2>
                          <div
                            className="cs_accordian_body"
                            style={{
                              display:
                                index === activeEducationIndex
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="mb-2">{item?.Description}</p>
                            <div className="doctor-gratuation-content">
                              <h3 className="">Institute</h3>
                              <p>{item?.Institute}</p>
                            </div>
                            <div className="doctor-gratuation-content">
                              <h3 className="">City</h3>
                              <p>{item?.City}</p>
                            </div>
                            <div className="doctor-gratuation-content">
                              <h3 className="">Year</h3>
                              <p>{item?.Year}</p>
                            </div>
                          </div>
                        </div>
                      )))
                    }
                  </div>
                </div>

                <div className="cs_section_heading cs_style_1">
                  <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold  cs_accent_color cs_fs_20 mb-2 post-graduation">
                    Post Graduation
                  </h3>
                  <div className="cs_height_5"></div>
                  <div className="cs_accordians cs_style1 cs_heading_color">
                    {doctorAbout?.post_graduation.length < 1 ? (<p>Coming soon! Awaited content to be revealed shortly.</p>):(doctorAbout?.post_graduation &&
                      doctorAbout?.post_graduation.map((item, index) => (
                        <div
                          className={`cs_accordian ${
                            index === activePostGraduationIndex ? "active" : ""
                          }`}
                          key={index}
                        >
                          <h2
                            className="cs_accordian_head cs_heading_color "
                            onClick={() =>
                              toggleAccordion("postgraduation", index)
                            }
                          >
                            {item?.Title}
                            <span className="cs_accordian_arrow">
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M-6.11959e-07 14C-2.74531e-07 21.7195 6.28053 28 14 28C21.7195 28 28 21.7195 28 14C28 6.28053 21.7195 -9.49388e-07 14 -6.11959e-07C6.28053 -2.74531e-07 -9.49388e-07 6.28053 -6.11959e-07 14ZM26.25 14C26.25 20.7548 20.7548 26.25 14 26.25C7.24522 26.25 1.75 20.7548 1.75 14C1.75 7.24522 7.24522 1.75 14 1.75C20.7548 1.75 26.25 7.24522 26.25 14ZM13.3814 8.13137C13.7233 7.78947 14.2769 7.78947 14.6186 8.13137L18.9936 12.5064C19.1645 12.6772 19.25 12.9012 19.25 13.125C19.25 13.3488 19.1645 13.5728 18.9936 13.7436C18.6517 14.0855 18.0981 14.0855 17.7564 13.7436L14.875 10.8622L14.875 19.25C14.875 19.7332 14.4837 20.125 14 20.125C13.5163 20.125 13.125 19.7332 13.125 19.25L13.125 10.8622L10.2436 13.7436C9.90172 14.0855 9.34806 14.0855 9.00637 13.7436C8.66469 13.4017 8.66447 12.8481 9.00637 12.5064L13.3814 8.13137Z"
                                  fill="#307BC4"
                                />
                              </svg>
                            </span>
                          </h2>
                          <div
                            className="cs_accordian_body"
                            style={{
                              display:
                                index === activePostGraduationIndex
                                  ? "block"
                                  : "none",
                            }}
                          >
                            <p className="mb-2">{item?.Description}</p>
                            <div className="doctor-gratuation-content">
                              <h3 className="">Speciality</h3>
                              <p>{item?.Speciality}</p>
                            </div>
                            <div className="doctor-gratuation-content">
                              <h3 className="">Institute</h3>
                              <p>{item?.Institute}</p>
                            </div>
                            <div className="doctor-gratuation-content">
                              <h3 className="">City</h3>
                              <p>{item?.City}</p>
                            </div>
                            <div className="doctor-gratuation-content">
                              <h3 className="">Year</h3>
                              <p>{item?.Year}</p>
                            </div>
                          </div>
                        </div>
                      )))
                    
                    }
                  </div>
                </div>

                <div>
                  <h3 className="cs_section_subtitle text-uppercase cs_accent_color cs_semibold  cs_accent_color cs_fs_20 experience-heading mb-0">
                    Experience
                  </h3>
                  <div className="cs_height_5"></div>
                  <div className="cs_section_heading cs_style_1">
                    <div className="cs_height_5"></div>
                    <div className="cs_accordians cs_style1 cs_heading_color ">
                      {
                      doctorAbout.experience.length < 1 ? (<p>Coming soon! Awaited content to be revealed shortly.</p>) : (doctorAbout.experience &&
                        doctorAbout.experience.map((item, index) => (
                          <div
                            className={`cs_accordian ${
                              index === activeExperienceIndex ? "active" : ""
                            }`}
                            key={index}
                          >
                            <h2
                              className="cs_accordian_head cs_heading_color"
                              onClick={() =>
                                toggleAccordion("experience", index)
                              }
                            >
                              {item?.Title}
                              <span className="cs_accordian_arrow">
                                <svg
                                  width="28"
                                  height="28"
                                  viewBox="0 0 28 28"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M-6.11959e-07 14C-2.74531e-07 21.7195 6.28053 28 14 28C21.7195 28 28 21.7195 28 14C28 6.28053 21.7195 -9.49388e-07 14 -6.11959e-07C6.28053 -2.74531e-07 -9.49388e-07 6.28053 -6.11959e-07 14ZM26.25 14C26.25 20.7548 20.7548 26.25 14 26.25C7.24522 26.25 1.75 20.7548 1.75 14C1.75 7.24522 7.24522 1.75 14 1.75C20.7548 1.75 26.25 7.24522 26.25 14ZM13.3814 8.13137C13.7233 7.78947 14.2769 7.78947 14.6186 8.13137L18.9936 12.5064C19.1645 12.6772 19.25 12.9012 19.25 13.125C19.25 13.3488 19.1645 13.5728 18.9936 13.7436C18.6517 14.0855 18.0981 14.0855 17.7564 13.7436L14.875 10.8622L14.875 19.25C14.875 19.7332 14.4837 20.125 14 20.125C13.5163 20.125 13.125 19.7332 13.125 19.25L13.125 10.8622L10.2436 13.7436C9.90172 14.0855 9.34806 14.0855 9.00637 13.7436C8.66469 13.4017 8.66447 12.8481 9.00637 12.5064L13.3814 8.13137Z"
                                    fill="#307BC4"
                                  />
                                </svg>
                              </span>
                            </h2>
                            <div
                              className="cs_accordian_body"
                              style={{
                                display:
                                  index === activeExperienceIndex
                                    ? "block"
                                    : "none",
                              }}
                            >
                              <p className="mb-2">{item?.Description}</p>
                              <div className="doctor-gratuation-content">
                                <h3 className="">StartDate</h3>
                                <p>{item?.StartDate}</p>
                              </div>
                              <div className="doctor-gratuation-content">
                                <h3 className="">EndDate</h3>
                                <p>{item?.EndDate}</p>
                              </div>

                              <div className="doctor-gratuation-content">
                                <h3 className="">Institute</h3>
                                <p>{item?.Institute}</p>
                              </div>
                            </div>
                          </div>
                        )))
                      
                      }
                    </div>
                  </div>
                  <div className="cs_height_85"></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <Footer />
    </>
  );
}
