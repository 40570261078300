import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "../Spinner";

function SecondFeaturedServices({ setIsdata }) {
  const [featuredService, setFeaturedService] = useState([]);
  const [loading, setLoading] = useState(true);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    document.title = "Home";
    getFeaturedService();
  }, []);

  const getFeaturedService = async () => {
    const response = await SendPostRequest("services/featured_services");
    if (response?.data.length < 5 && sitedata?.site_type === "hospitals") {
      setIsdata(true);
    }
    setFeaturedService(response?.data);
    setLoading(false);
  };

  const options = {
    loop: true,
    nav: false,
    dots: false,
    autoplay: true,
    autoplayTimeout: 2500,
    autoplayHoverPause: true,
    smartSpeed: 1000,
    margin: 30,
    responsive: {
      0: { items: 1 },
      600: { items: 2 },
      1000: { items: 3 },
    },
  };

  // Only render section if there is data and it's not loading
  return (
    <>
      {loading ? (
        <div
          style={{ marginBottom: "100px" }}
          className="d-flex align-items-center justify-content-center "
        >
          <Spinner />
        </div>
      ) : featuredService.length > 0 ? (
        <section id="services-section" className="services-section semi-dark">
          <div className="container">
            <div className="row">
              <div className="offset-md-2 col-md-8">
                <div className="title-wrap text-center">
                  <div className="section-title margin-bottom-60">
                    <h2 className="section-title margin-top-5 mb-0 text-uppercase">
                      Our Specialities
                    </h2>
                    <span className="section-border-bottom center"></span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <OwlCarousel className="service-main-wrapper" {...options}>
                  {featuredService.map((service, index) => (
                    <div className="item" key={index}>
                      <div className="service-box-wrap service-box-style-1">
                        <div className="service-box-inner">
                          {/* <div className="service-thumb mb-4">
                          <img
                            className="img-fluid squared w-100 b-radius-10"
                            src={service?.service_image_url}
                            width="425"
                            height="282"
                            alt={service?.service_title}
                          />
                        </div> */}
                          <div
                            className="service-thumb mb-4 new-fservices-img-wrapper"
                            style={{
                              backgroundImage: `url(${service?.service_image_url})`,
                            }}
                          ></div>
                          <div className="service-content">
                            <h3 className="service-title mb-2 new-fservices-title-wrapper">
                              {service.service_title}
                            </h3>
                            <div
                              className="service-desc mb-4"
                              dangerouslySetInnerHTML={{
                                __html: service.service_description.slice(
                                  0,
                                  110
                                ),
                              }}
                            />
                            <div className="service-link">
                              <Link
                                to={`/speciality/${
                                  service?.uid
                                }/${service?.service_title
                                  .replaceAll(" ", "-")
                                  .replaceAll("/", "-")}`}
                                rel="noopener noreferrer"
                                className="btn btn-default link"
                              >
                                Read More
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
      ) : null}
    </>
  );
}

export default SecondFeaturedServices;
