
function createSlug(input) {
    return input
      .toString()
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-") 
      .replace(/[^a-z0-9-]/g, "") 
      .replace(/-{2,}/g, "-") 
      .substring(0, 50); 
  } 

export default createSlug;
