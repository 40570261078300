// patientReducer.js
import {
  SET_PATIENT_LOGIN,
  SET_PATIENT_LOGOUT,
  SET_PATIENT_PROFILE,
} from "../global/actionTypes";

const INIT_STATE = {
  patientLogin: null,
};

const patientReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PATIENT_LOGIN:
      return {
        ...state,
        patientLogin: action.payload,
      };

    case SET_PATIENT_LOGOUT:
      return {
        ...state,
        patientLogin: null,
      };
    default:
      return state;
  }
};

export default patientReducer;
