import React, { useState } from "react";

function BodyWeightCal() {
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [gender, setGender] = useState("male");
  const [result, setResult] = useState("");

  const IdealBodyWeight = () => {
    let IBM;
    const heightVal = parseFloat(height);
    const weightVal = parseFloat(weight);

    if (gender === "male") {
      IBM = 50 + 2.3 * (heightVal - 60);
    } else if (gender === "female") {
      IBM = 45.5 + 2.3 * (heightVal - 60);
    }

    setResult(`Ideal Body Weight (IBW): ${IBM.toFixed(2)} kg`);
  };

  return (
    <>
      <div id="body_weight_tab">
        <div className="row">
          <div className="col-md-6">
            <h4>ADJUSTED BODY WEIGHT (AJBW) AND IDEAL BODY WEIGHT (IBW)</h4>
            <h6 style={{ marginTop: "20px", marginLeft: "25px" }}>
              <strong>IDEAL BODY WEIGHT</strong>
            </h6>
            <p style={{ fontSize: 16, textAlign: "justify" }}></p>
            <ul style={{ marginLeft: "25px" }}>
              <li className="list">Estimate Ideal body weight in (kg)</li>
              <li className="list">
                Males: IBW = 50 kg + 2.3 kg for each inch over 5 feet.
              </li>
              <li className="list">
                Females: IBW = 45.5 kg + 2.3 kg for each inch over 5 feet.
              </li>
            </ul>
            <p></p>
            <div className="clearfix">
              <br />
            </div>
          </div>
          <div className="col-md-6">
            <div style={{ width: "100%" }}>
              <div className="body-fat-ratio-heading">
                <h5> IDEAL BODY WEIGHT &amp; ADJUSTED BODY WEIGHT</h5>
              </div>
              <div style={{ backgroundColor: "#F7F7F7" }}>
                <div className="clearfix">
                  <br />
                </div>
                <form
                  style={{
                    width: "95%",
                    marginLeft: "15px",
                    marginTop: "20px",
                  }}
                  id="IdealBodyWeightForm"
                >
                  <div className="row" style={{ borderBottom: "0px" }}>
                    <div className="col-lg-6 mb-3">
                      <label
                        className="control-label"
                        htmlFor="exampleInputuname_1"
                      >
                        <strong>Height in Inches</strong>
                      </label>
                      <input
                        className="form-control"
                        id="height"
                        name="height"
                        placeholder="Height"
                        type="text"
                        value={height}
                        onChange={(e) => setHeight(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-6">
                      <label
                        className="control-label"
                        htmlFor="exampleInputuname_1"
                      >
                        <strong>Weight in KG</strong>
                      </label>
                      <input
                        className="form-control"
                        id="weight"
                        name="weight"
                        placeholder="Weight"
                        type="text"
                        value={weight}
                        onChange={(e) => setWeight(e.target.value)}
                      />
                    </div>
                    <div className="col-lg-12">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        <strong>Gender</strong>
                      </label>
                      <select
                        name="gender"
                        id="gender"
                        className="form-control"
                        value={gender}
                        onChange={(e) => setGender(e.target.value)}
                      >
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div className="col-lg-12 col-lg-offset-3 d-flex justify-content-around mt-4">
                      <button
                        type="button"
                        // className="btn btn-success btn-rounded btn-anim "
                        className="calculate"
                        onClick={IdealBodyWeight}
                      >
                        Calculate
                      </button>
                      <button
                        type="button"
                        className="clear"
                        // onClick={resetForm}
                      >
                        Reset
                      </button>
                    </div>
                    <div
                      id="IdealBodyWeightResult"
                      style={{ textAlign: "center", fontSize: "130%" }}
                      className="mt-3"
                    >
                      {result}
                    </div>
                    <div className="clearfix">
                      <br />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BodyWeightCal;
