import React, { useEffect, useState } from "react";

import Footer from "../Footer";
import Header from "../Header";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function PatientPortal() {

  const sitedata = useSelector((store) => store.global.loginData);
  
  function capitalize(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  useEffect(() => {
      const title = sitedata?.site_title || "";
      document.title = capitalize(title) + " :: Patient-Portal - ClinTa";
    window.scrollTo(0, 0);
  }, []);

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
   let themeColor;
   if(sitedata){
      themeColor = sitedata?.theme_color?.split(".css").join("");
   }else{}
    
    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Clinta Health Care</h2>
          </div>
        </div>
      </section>

      <div className="cs_height_100"></div>
      <div className="container">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">Home</Link>
          </li>
          <li className="breadcrumb-item">
            <Link to="/patient-portal">Patient-Portal</Link>
          </li>
        </ol>
        <div className="cs_height_18"></div>
        <h1 className=" mb-0 clinta-health-care">CLINTA HEALTH CARE</h1>
      </div>
      <div className="cs_height_18"></div>
      <section>
        <div className="container">
          <div className="row ">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="cs_height_28"></div>
              <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_medium">
                My Patients Can Maintain Their Own Portal For All Kinds Of
                Health Records And Online Appointments, And All These Facilities
                Can Be Availed Without Spending Money.
              </p>
              <div className="cs_height_22"></div>
              <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_medium">
                I Am Glad To Introduce ClinTa Healthcare To My Patients. ClinTa
                Healthcare Is A Password-Protected And Web-Based Interactive
                Service That Allows You To Securely Access And Track Your Health
                Information.
              </p>
              <div className="cs_height_22"></div>
              <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_medium">
                Using Your Personal Computer Or Smart Phone, You Can Sign In To
                ClinTa And See Your Test Results, Medication You Are Taking And
                A Summary Of Your Current Health Conditions.
              </p>
              <div className="cs_height_22"></div>
              <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_medium">
                Clinta Facilitates You To View A Summary Of Your Health
                Information Based On Your Visits To Your Doctor. You Can View
                Your Test Results, See Your Appointments (Both Future And In The
                Recent Past), Treatments You Have Received Such As
                Immunizations, Any Allergies You May Have, And What Medications
                Your Records Show That You Are Taking.
              </p>
              <div className="cs_height_22"></div>
              <p className="cs_banner_subtitle cs_fs_20 mb-0 cs_medium">
                For Further Details Please Visit{" "}
                <span style={{ color: "#dbb126" }}>
                  <Link to="https://www.clinta.biz/healthcare/"> Clinta.Biz/Healthcare</Link>
                </span>
              </p>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="cs_banner_img">
                <img src="/assets/img/doctors/clinta-public.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="cs_height_100"></div>
      <Footer />
    </>
  );
}
