// import { createStore, applyMiddleware, compose } from 'redux';
// import { persistStore, persistReducer } from 'redux-persist';
// import thunkMiddleware from 'redux-thunk';
// import rootReducer from './reducers';
// import storage from 'redux-persist/lib/storage';

// const persistConfig = {
//   key: 'root',
//   storage,
//   whitelist: ['routes', 'ui'],
// };

// const persistedReducer = persistReducer(persistConfig, rootReducer); 

// const composeEnhancers = window._REDUX_DEVTOOLS_EXTENSION_COMPOSE_ || compose;
// const middleware = applyMiddleware(thunkMiddleware);

// const store = createStore(persistedReducer, composeEnhancers(middleware));

// const persistor = persistStore(store);

// export { store, persistor };


import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './reducers';
import { composeWithDevTools } from 'redux-devtools-extension'; // Import composeWithDevTools
import thunkMiddleware from 'redux-thunk';


const persistConfig = {
  key: 'root',
  storage,
  // Other configuration options
};

const middleware = applyMiddleware(thunkMiddleware);

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Create the store with DevTools extension
const store = createStore(
  persistedReducer,composeWithDevTools(middleware)
);

const persistor = persistStore(store);

export { store, persistor };