import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";

function FavoritesTabs({ onTabSelect }) {
  const [selectedTab, setSelectedTab] = useState("DietItems");
  const handleNavItemClicked = (heading) => {
    onTabSelect(heading);
    // setOpenDropdown(null);
    setSelectedTab(heading); // Close the dropdown when a tab is clicked
  };
  return (
    <div className="favoritesTabs">
      <Navbar bg="" expand="lg" variant="dark">
        <Navbar.Toggle aria-controls="main_nav" />
        <Navbar.Collapse id="main_nav">
          <Nav className="mr-auto p-3">
            <Nav.Link
              className={selectedTab === "DietItems" ? "active" : ""}
              onClick={() => handleNavItemClicked("DietItems")}
            >
              Diet Items
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "tips" ? "active" : ""}
              onClick={() => handleNavItemClicked("tips")}
            >
              Tips & Guide
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default FavoritesTabs;
