import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { Modal, Carousel } from "react-bootstrap";
import { SendPostRequest } from "../http";
import Spinner from "./Spinner";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { BsTypeH1 } from "react-icons/bs";

export default function Gallery() {
  const sitedata = useSelector((store) => store.global.loginData);

  const [showModal, setShowModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  const [my_loader, setMyLoader] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [doctorGallery, setDoctorGallery] = useState([]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Gallery - ClinTa";
    window.scrollTo(0, 0);

    setCarouselImages(
      galleryData?.length > 0 &&
        galleryData?.map((item) => (
          <Carousel.Item key={item.id}>
            <img src={item.gallery_image} alt={item.imgAlt} />
          </Carousel.Item>
        ))
    );
    getGalleyData();
  }, []);

  const [isData, setIsData] = useState(false);
  const getGalleyData = async () => {
    setMyLoader(true);
    const response = await SendPostRequest("gallery/list");
    if (!response?.data || response?.data.length < 1) {
      setIsData(true);
    } else {
      setGalleryData(response.data);
      setMyLoader(false);
    }
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(0);
    setShowModal(false);
    setSelectedImage(null);
  };

  const handleImageClick = async (uid) => {
    const response = await SendPostRequest("gallery/details", {
      images_uid: uid,
    });
    if (!response?.data || response?.data.length < 1) {
    } else {
      setDoctorGallery(response.data);
      setShowModal(true);
    }
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const handleCarouselSlide = (selectedIndex) => {
    setCurrentImageIndex(selectedIndex);
  };
  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Gallery</h2>
          </div>
        </div>
      </section>

      {isData ? (
        <>
          <div className="cs_height_54"></div>

          <div className="d-flex align-items-center justify-content-center">
            <div>
              <img src="assets/img/search.png" alt="" />
              <div className="cs_height_54"></div>
              <h1 className="text-center">Under Construction</h1>
            </div>
          </div>
          <div className="cs_height_54"></div>
        </>
      ) : (
        <div className="container">
          <div className="row cs_style1 cs_has_gutter_24 main-container mb-5">
            {galleryData?.length === 0 ? (
              <div
                style={{ marginBottom: "100px" }}
                className="d-flex align-items-center justify-content-center "
              >
                {my_loader && <Spinner />}
              </div>
            ) : (
              galleryData?.length > 0 &&
              galleryData?.map((item, index) => (
                <div className="col-lg-4 col-md-6 col-sm-12 mt-5" key={item.id}>
                  <div className="cs_portfolio cs_style_1 cs_radius_20 overflow-hidden">
                    <div
                      className="cs_portfolio_img cs_bg_filed st_lightbox_item d-block dfgehththth"
                      style={{
                        backgroundImage: `url(${item?.gallery_image})`,
                        // backgroundImage: `url(data:image/jpeg;base64,${item?.gallery_image})`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        cursor: "pointer",
                      }}
                      onClick={() => handleImageClick(item?.uid)}
                    >
                      <p className="gallery-title">
                        {item?.gallery_title.split(" ").slice(0, 10).join(" ")}
                      </p>
                      <span className="cs_link_hover">
                        <i className="fas fa-arrows-alt"></i>
                      </span>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <div className="cs_height_54"></div>
        </div>
      )}

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        className="Gallery-modal"
        centered
      >
        <Modal.Header>
          <h5 className="m-0">
            {doctorGallery[selectedImageIndex]?.gallery_title}
          </h5>
          <button className="close-button" onClick={handleCloseModal}>
            <i className="fas fa-times"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Carousel
          
            activeIndex={selectedImageIndex}
            onSelect={(index) => setSelectedImageIndex(index)}
            prevIcon={<span className="carousel-arrow hide-on-mobile">‹</span>} 
            nextIcon={<span className="carousel-arrow hide-on-mobile">›</span>}
            interval={30000}s
            indicators={false}
            pause="hover" 

          >
            {doctorGallery?.length > 0 &&
              doctorGallery?.map((item, index) => (
                <Carousel.Item key={index}>
                  {doctorGallery.length === 0 ? (
                    <div
                      style={{ marginBottom: "100px" }}
                      className="d-flex align-items-center justify-content-center "
                    >
                      <Spinner />
                    </div>
                  ) : (
                    <div
                      className="carousel-background-image"
                      style={{
                        backgroundImage: `url(${item?.gallery_image})`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        width:"100%",
                        minHeight:"400px"
                      }}
                    ></div>
                  )}
                </Carousel.Item>
              ))}
          </Carousel>
        </Modal.Body>
        {doctorGallery[selectedImageIndex]?.gallery_desc && (
          <Modal.Footer>
            <p>{doctorGallery[selectedImageIndex]?.gallery_desc}</p>
          </Modal.Footer>
        )}
      </Modal>

      <Footer />
    </>
  );
}
