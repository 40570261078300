import React, { useState, useEffect } from "react";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";
import { Link } from "react-router-dom";
import { FiFacebook } from "react-icons/fi";

export default function Contact() {
  const [contactData, setContactData] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getContactData();
  }, []);

  const getContactData = async () => {
    const response = await SendPostRequest("content/contactus");
    setContactData(response?.data);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  let themeColor;
  if (sitedata) {
    themeColor = sitedata?.theme_color?.split(".css").join("");
  } else {
  }
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{
        //   backgroundImage:
        //     sitedata.site_type === "hospitals"
        //       ? "url('/assets/img/doctors/banner_bg.svg')"
        //       : "url('/assets/img/doctors/banner_bg_gold.svg')",
        // }}

        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {" "}
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72">Contact Us</h2>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="cs_height_54"></div>

        <section>
          <div className="container cs_mt_minus_110">
            <div className="cs_height_70 cs_height_lg_50"></div>
            <div className="row g-4 g-xl-3 g-xxl-5">
              <div className="col-xl-4">
                <div className="cs_iconbox cs_style_11 cs_radius_25">
                  <div className="cs_iconbox_icon">
                    <img
                      src={`/assets/img/contact/icon_1_${themeColor}.svg`}
                      alt="Icon"
                    />
                    {/* {sitedata.theme_color === "blue" ? (
                      <img src="/assets/img/contact/icon_1_blue.svg" alt="Icon" />
                    ) : (
                      <img
                        src="/assets/img/contact/icon_1_gold.svg"
                        alt="Icon"
                      />
                    )} */}

                    {/*  */}
                  </div>
                  <div className="cs_iconbox_right">
                    <h3 className="cs_iconbox_title cs_fs_24 mb-0">Phone</h3>
                    <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                      {contactData &&
                      contactData[0]?.land_line_no?.length > 0 ? (
                        <a href={`tel:${contactData[0].land_line_no}`}>
                          {contactData[0].land_line_no}
                        </a>
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="cs_iconbox cs_style_11 cs_radius_25">
                  <div className="cs_iconbox_icon">
                    <img
                      src={`/assets/img/contact/icon_2_${themeColor}.svg`}
                      alt="Icon"
                    />

                    {/*  */}
                  </div>
                  <div className="cs_iconbox_right">
                    <h3 className="cs_iconbox_title cs_fs_24 mb-0">Email</h3>
                    <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                      {contactData && contactData.length > 0 ? (
                        <a
                          href={`mailto:${
                            contactData?.email?.length > 0
                              ? contactData[0].email
                              : "info@reliancehospital.org"
                          }`}
                        >
                          {contactData[0].email}
                        </a>
                      ) : (
                        "--"
                      )}
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="cs_iconbox cs_style_11 cs_radius_25">
                  <div className="cs_iconbox_icon">
                    {/* <img
                      src={`/assets/img/contact/icon_3_${themeColor}.svg`}
                      alt="Icon"
                    /> */}
                    <FiFacebook className="sidebar-facebook-icon" />
                  </div>
                  <div className="cs_iconbox_right">
                    <h3 className="cs_iconbox_title cs_fs_24 mb-0">Facebook</h3>
                    <p className="cs_iconbox_subtitle mb-0 cs_heading_color">
                      {contactData[0]?.facebook_link?.length > 0 ? (
                        <Link target="blank" to={contactData[0].facebook_link}>
                          <span>
                            {sitedata.site_title ? sitedata.site_title : "--"}
                          </span>
                          {/* {sitedata?.site_type === "hospitals" ? (
                          <span>Reliance Hospital</span>
                        ) : (
                          <span>Burhan Haque</span>
                        )} */}
                        </Link>
                      ) : (
                        <span>
                          {sitedata.site_title ? sitedata.site_title : "--"}
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="cs_height_35"></div>
            <div className="cs_map contact-map">
              {/* <div
                dangerouslySetInnerHTML={{
                  __html: contactData > 0 || contactData[0].google_map ? contactData[0].google_map : '',
                }}
              /> */}
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    contactData &&
                    contactData.length > 0 &&
                    contactData[0].google_map
                      ? contactData[0].google_map
                      : "",
                }}
              />
            </div>
          </div>
        </section>
      </div>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}
