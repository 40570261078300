import React, { useEffect, useState, useRef } from "react";
import Footer from "../Footer";
import Header from "../Header";
import { useSelector } from "react-redux";
import Select from "react-select";
import { SendPostRequest } from "../../http";
import { capitalize } from "@mui/material";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function CareerDetail() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [loading, setLoading] = useState(true);
  const [careerDetailData, setCareerDetailData] = useState(true);
  const [segmentList, setSegmentList] = useState([]);
  const { id } = useParams();

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }
    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getCareerData();
  }, []);

  const getCareerData = async () => {
    const response = await SendPostRequest("careers/job-detail", {
      job_id: id,
    });
    setCareerDetailData(response?.data);
    setSegmentList(
      response?.data?.segments_list?.map((segment) => ({
        value: segment.id,
        label: segment.title,
      }))
    );
    setLoading(false);
  };

  const [cvData, setCvData] = useState({
    full_name: "",
    email: "",
    contact_no: "",
    address: "",
    segments: "",
    experience: "",
    education: "",
    certifications: "",
    previous_work: "",
    languages: "",
    linkedin: "",
    message: "",
    cv_file: null,
    cv_ext: "",
  });
  const [careersData, setCareersData] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef(null);

  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Contact - ClinTa";
    window.scrollTo(0, 0);
    getCareerData();
  }, [sitedata]);

  const handleFileChange = (e) => {
    let name = e.target.name;
    let file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result.split(",")[1];
      setCvData((prevData) => ({
        ...prevData,
        cv_ext: file.name.split(".").pop(),
        [name]: base64String,
      }));
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cvData.full_name) {
      toast.error("InComplete Form");
      return;
    } else if (!cvData.contact_no) {
      toast.error("InComplete Form");
      return;
    } else if (cvData.contact_no.length > 14 || cvData.contact_no.length < 10) {
      toast.error("Invalid Contact Number");
      return;
    } else if (!cvData.email) {
      toast.error("InComplete Form");
      return;
    } else if (!cvData.segments) {
      toast.error("InComplete Form");
      return;
    } else if (!cvData.cv_file) {
      toast.error("InComplete Form");
      return;
    }

    try {
      setIsSubmitting(true);
      const response = await SendPostRequest("careers/drop-cv", {
        ...cvData,
        position: id,
      });
      formRef.current.reset();
      if (response.status === "success") {
        setCvData({
          full_name: "",
          email: "",
          contact_no: "",
          address: "",
          segments: "",
          experience: "",
          education: "",
          certifications: "",
          previous_work: "",
          languages: "",
          linkedin: "",
          message: "",
          cv_file: null,
          cv_ext: "",
        });
        toast.success(response.message);
      } else {
        let errorMessage = response.message || "An error occurred";
        toast.error(`Error: ${errorMessage}`);
        console.error("Error fetching data:", errorMessage);
      }
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.error("Error fetching data:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCvData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="container">
          <div>
            <h2 className="cs_banner_title cs_fs_72">
              {careerDetailData?.title}
            </h2>
          </div>
        </div>
      </section>
      <div className="cs_height_42 cs_height_xl_80"></div>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-7 pr-5">
              <div className="team-details-desc">
                <h3>About Postion</h3>
                <p>{careerDetailData?.content || "--"}</p>

                <ul className="team-info">
                  <li>
                    <span>Department</span>

                    <ul>
                      <li>{careerDetailData?.segments || "--"}</li>
                      {/* <li>Endocrinology</li> */}
                      {/* <li>Paediatric Medicine</li>
                      <li>Urology</li> */}
                    </ul>
                  </li>

                  <li>
                    <span>Education</span>

                    <ul>
                      <li>{careerDetailData?.education || "--"}</li>
                      {/* <li>
                        Doctor of Medicine, University of Texas, USA (1990)
                      </li> */}
                      {/* <li>
                        Medical Orientation Program, St. Louis University (St.
                        Louis, Missouri 1996)
                      </li> */}
                    </ul>
                  </li>

                  <li>
                    <span>Experience</span>

                    <ul>
                      <li>{careerDetailData?.experience || "--"}</li>
                    </ul>
                  </li>

                  <li>
                    <span>Certifications</span>

                    <ul>
                      <li>{careerDetailData?.certifications || "--"}</li>
                    </ul>
                  </li>
                  <li>
                    <span>Languages</span>

                    <ul>
                      <li>{careerDetailData?.languages || "--"}</li>
                    </ul>
                  </li>

                  {/* <li>
                    <span>Education</span>

                    <ul>
                      <li>
                        Vice President and Chief Medical Officer, Kessler
                        Institute for Rehabilitation
                      </li>
                      <li>
                        Medical Corporation Professor, Institute Of Coast
                        Private Hospital Campus
                      </li>
                    </ul>
                  </li> */}

                  {/* <li>
                    <span>Address</span>

                    <ul>
                      <li>
                        Suite 27, Medical Centre, The Sunshine Coast Private
                        Hospital, QLD 4556
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span>Phone</span>

                    <ul>
                      <li>
                        <a href="#">+1-23-345-6789</a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span>Email</span>

                    <ul>
                      <li>
                        <a href="#">myemail@yourdomain.com</a>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <span>Website</span>

                    <ul>
                      <li>
                        <a href="#">www.agatonronald.com</a>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="cs_section_heading cs_style_1">
                <div className="team-details-desc">
                  <h3>Enter Your Detail</h3>
                </div>
              </div>
              <form ref={formRef} onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Full Name <span className="asterisk">*</span>
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Full Name"
                      name="full_name"
                      value={cvData.full_name}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  {/* <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Contact Number
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Contact Number"
                      name="contact_no"
                      value={cvData.contact_no}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div> */}

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Contact Number <span className="asterisk">*</span>
                    </label>
                    <input
                      type="number"
                      className="cs_form_field"
                      placeholder="Enter Contact Number"
                      name="contact_no"
                      value={cvData.contact_no}
                      onChange={handleInputChange}
                      maxLength={11}
                      minLength={10}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Email Address <span className="asterisk">*</span>
                    </label>
                    <input
                      type="email"
                      className="cs_form_field"
                      placeholder="Enter Email Address"
                      name="email"
                      value={cvData.email}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Departments <span className="asterisk">*</span>
                    </label>
                    {/* <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Specialization"
                      name="specialization"
                      value={cvData.specialization}
                      onChange={handleInputChange}
                    /> */}
                    <Select
                      className="gallery-select-field"
                      name="segments"
                      options={segmentList}
                      placeholder="Select...."
                      value={segmentList.find(
                        (option) => option?.value === cvData?.segments
                      )}
                      onChange={(option) =>
                        setCvData((prevData) => ({
                          ...prevData,
                          segments: option?.value,
                        }))
                      }
                    />

                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  {/* <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Education
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Education Background"
                      name="education"
                      value={cvData.education}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Years Of Experience
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Year Of Experience"
                      name="experience"
                      value={cvData.experience}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Languages Spoken
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Language Spoken"
                      name="languages"
                      value={cvData.languages}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div> */}
                  <div className="col-lg-12">
                    <label className="cs_input_label cs_heading_color">
                      Upload Your CV <span className="asterisk">*</span>
                    </label>
                    <input
                      type="file"
                      className="cs_form_field"
                      placeholder="Upload CV"
                      name="cv_file"
                      onChange={handleFileChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  {/* <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Linkedin Profile
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Linkedin Profile"
                      name="linkedin"
                      value={cvData.linkedin}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Postal Address
                    </label>
                    <input
                      type="text"
                      className="cs_form_field"
                      placeholder="Enter Postal Address"
                      name="address"
                      value={cvData.address}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Certificates
                    </label>
                    <textarea
                      rows="3"
                      className="textarea-cs_form_field"
                      placeholder="Enter Relevant Certifications"
                      name="certifications"
                      value={cvData.certifications}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>

                  <div className="col-lg-6">
                    <label className="cs_input_label cs_heading_color">
                      Work Experience
                    </label>
                    <textarea
                      rows="3"
                      className="textarea-cs_form_field"
                      placeholder="Enter Previous Experience"
                      name="previous_work"
                      value={cvData.previous_work}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div> */}

                  <div className="col-lg-12">
                    <label className="cs_input_label cs_heading_color">
                      Message
                    </label>
                    <textarea
                      rows="3"
                      className="textarea-cs_form_field"
                      placeholder="Type Your Message Here"
                      name="message"
                      value={cvData.message}
                      onChange={handleInputChange}
                    />
                    <div className="cs_height_42 cs_height_xl_25"></div>
                  </div>
                  <div className="col-lg-12">
                    <div className="cs_height_18"></div>
                    <div className="d-flex justify-content-end">
                      <button
                        className="cs_btn cs_style_1 "
                        type="submit"
                        disabled={isSubmitting}
                      >
                        <span>{isSubmitting ? "Submitting..." : "Submit"}</span>
                        <i>
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <div className="cs_height_50 cs_height_lg_50"></div>

      <div className="cs_height_105"></div>
      <Footer />
    </>
  );
}
