import React, { useState, useEffect, useRef } from "react";
import "./ThirdMainSlider.css";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";

function ThirdMainSlider({ setIsdata }) {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(null);
  const sliderRef = useRef(null);
  const [homeBanners, setHomeBanners] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    brandingData();
  }, []);

  const brandingData = async () => {
    const siteTheme = sitedata?.options?.theme;
    const response = await SendPostRequest("branding/banners", {
      theme: siteTheme,
    });
    if (!response?.data || response?.data?.length < 1) {
      setIsdata(true);
    } else {
      setHomeBanners(response?.data);
    }
  };

  const images = [
    {
      src: "./assets/image/img1.jpg",
      type: "FLOWER",
      description: "Lorem ipsum dolor sit amet...",
    },
    {
      src: "./assets/image/img2.jpg",
      type: "NATURE",
      description: "Lorem ipsum dolor sit amet...",
    },
    {
      src: "./assets/image/img4.jpg",
      type: "PLANT",
      description: "Lorem ipsum dolor sit amet...",
    },
    {
      src: "./assets/image/img3.jpg",
      type: "NATURE",
      description: "Lorem ipsum dolor sit amet...",
    },
  ];

  const moveSlider = (direction) => {
    setDirection(direction);
    setIndex((prevIndex) => {
      return direction === "next"
        ? (prevIndex + 1) % images.length
        : (prevIndex - 1 + images.length) % images.length;
    });
  };

  useEffect(() => {
    const handleAnimationEnd = () => {
      if (sliderRef.current) {
        sliderRef.current.classList.remove("next", "prev");
        setDirection(null);
      }
    };

    const sliderElement = sliderRef.current;
    if (sliderElement) {
      sliderElement.addEventListener("animationend", handleAnimationEnd);
    }

    return () => {
      if (sliderElement) {
        sliderElement.removeEventListener("animationend", handleAnimationEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (sliderRef.current && direction) {
      sliderRef.current.classList.add(direction);
    }
  }, [index, direction]);

  const getReorderedImages = (offset = 0) => {
    const reorderedImages = [...homeBanners];
    const currentIndex = (index + offset) % images.length;
    return reorderedImages
      .slice(currentIndex)
      .concat(reorderedImages.slice(0, currentIndex));
  };

  return (
    <div className="slider" ref={sliderRef}>
      <div className="list">
        {homeBanners.map((data, i) => (
          <div
            className={`item ${
              i === index
                ? "active"
                : index === images.length - 1 && i === 0
                ? "prv"
                : i === index + 1
                ? "prv"
                : ""
            }`}
            key={i}
          >
            <div className="shadow"></div>
            <img src={`${data?.banner_image}`} alt="" />
            <div className="content">
              <div className="type">{data?.banner_title}</div>
              <div className="description">{data?.banner_description}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="thumbnail">
        {getReorderedImages(1)
          ?.slice(0, 2)
          .map((image, i) => (
            <div className={`item ${i === 0 ? "active" : ""}`} key={i}>
              <img src={`${image?.banner_image}`} alt="" />
            </div>
          ))}
      </div>
      <div className="nextPrevArrows">
        <button className="prev" onClick={() => moveSlider("prev")}>
          &lt;
        </button>
        <button className="next" onClick={() => moveSlider("next")}>
          &gt;
        </button>
      </div>
    </div>
  );
}

export default ThirdMainSlider;
