import React, { useState } from "react";
import CalculatorTabs from "./CalculatorTabs/CalculatorTabs";
import Bodyfat from "./CalculatorTabs/Bodyfat";
import Bmi from "./CalculatorTabs/Bmi";

import "./CalculatorTabs/calculators"
import BodyWeightCal from "./CalculatorTabs/BodyWeightCal";
import ChildernHeightPredictor from "./CalculatorTabs/ChildernHeightPredictor";
import Edd from "./CalculatorTabs/Edd";
import HeightCounter from "./CalculatorTabs/HeightCounter";
import WeightConverter from "./CalculatorTabs/WeightConverter";

function Calculator() {
  const [selectedTab, setSelectedTab] = useState("Bodyfat");
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };
  return (
    <div className="calculator">

      <h3>Calculator</h3>

      <div className="tabs-sec">


      <div className="row">
        <div className="col-3">
          <CalculatorTabs onTabSelect={handleTabSelect} />
        </div>
        <div className="col-9">
          {selectedTab === "Bodyfat" && <Bodyfat />}
          {selectedTab === "BMI" && <Bmi />}
          {selectedTab === "Body_weight_calculator" && <BodyWeightCal />}
          {selectedTab === "Childern_height_predictor" && <ChildernHeightPredictor />}
          {selectedTab === "Edd" && <Edd />}
          {selectedTab === "Height_Converter" && <HeightCounter />}
          {selectedTab === "Weight_Converter" && <WeightConverter />}
        </div>
      </div>
      </div>
    </div>
  );
}

export default Calculator;
