import React, { useState, useEffect } from "react";
import $ from "jquery";
import Footer from "./Footer";
import Header from "./Header";
import { SendPostRequest } from "../http";
import { useSelector } from "react-redux";
import { capitalize } from "@mui/material";

export default function Ehealth() {
  const [ehealthData, setEhealthData] = useState({});
  const sitedata = useSelector((store) => store.global.loginData);

  const sections = [
    {
      title: "Mobile Payments",
      content: ehealthData?.mobile_payment_info,
      index: "payment",
    },
    {
      title: "Bank Deposit/ Transfer",
      content: ehealthData?.bank_deposit,
      index: "transfer",
    },
    // Add more sections as needed
  ];

  const [activeIndex, setActiveIndex] = useState(null);
  const [activePaymentIndex, setActivePaymentIndex] = useState(null);
  const [activeTransferIndex, setActiveTransferIndex] = useState(null);

  const toggleAccordion = (section, index) => {
    switch (section) {
      case "payment":
        setActivePaymentIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;
      case "transfer":
        setActivePaymentIndex((prevIndex) =>
          prevIndex === index ? null : index
        );
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: E Health - ClinTa";

    window.scrollTo(0, 0);

    getEHealthData();
  }, []);

  const getEHealthData = async () => {
    const response = await SendPostRequest("ehealth");
    setEhealthData(response?.data);
  };

  const [backgroundImage, setBackgroundImage] = useState("");
  useEffect(() => {
    let themeColor;
    if (sitedata) {
      themeColor = sitedata?.theme_color?.split(".css").join("");
    } else {
    }

    setBackgroundImage(`/assets/img/doctors/banner_bg_${themeColor}.svg`);
  }, [sitedata?.theme_color]);

  return (
    <>
      <Header />
      <section
        className="cs_banner cs_style_5 cs_bg_filed"
        // style={{ backgroundImage: "url('/assets/img/doctors/banner_bg.svg')" }}
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        {/* <div className="cs_banner_img">
          <img src="/assets/img/doctors/banner_img.png" alt="Banner" />
        </div> */}
        <div className="container">
          <div className="cs_banner_text">
            <h2 className="cs_banner_title cs_fs_72 text-capitalize">
              {ehealthData?.ehealth_title}
            </h2>
          </div>
        </div>
      </section>
      <div className="container">
        <section className="e-health-intro">
          <h2 className=" cs_fs_52" style={{ textAlign: "center" }}>
            INTRODUCTION
          </h2>
          <p>{ehealthData?.ehealth_description}</p>
        </section>

        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="cs_section_heading cs_style_1">
                <div className="cs_height_5"></div>
                <h2 className="cs_section_title cs_fs_72 m-0">
                  <img
                    src={
                      ehealthData?.ehealth_image
                        ? `data:image/jpeg;base64, ${ehealthData?.ehealth_image}`
                        : "assets/img/search.png"
                    }
                    alt="Banner"
                  />
                </h2>
              </div>
              <div className="cs_height_70 cs_height_lg_50"></div>
            </div>
            <div className="col-lg-7">
              <div className="cs_accordians cs_style1 cs_heading_color">
                {sections.map((section, index) => (
                  <div
                    className={`cs_accordian ${
                      index === activePaymentIndex ? "active" : ""
                    }`}
                    key={index}
                  >
                    <h2
                      className={`cs_accordian_head cs_heading_color ${
                        index === activeIndex ? "active" : ""
                      }`}
                      onClick={() => toggleAccordion(section.index, index)}
                    >
                      {section.title}
                      <span className="cs_accordian_arrow">
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M-6.11959e-07 14C-2.74531e-07 21.7195 6.28053 28 14 28C21.7195 28 28 21.7195 28 14C28 6.28053 21.7195 -9.49388e-07 14 -6.11959e-07C6.28053 -2.74531e-07 -9.49388e-07 6.28053 -6.11959e-07 14ZM26.25 14C26.25 20.7548 20.7548 26.25 14 26.25C7.24522 26.25 1.75 20.7548 1.75 14C1.75 7.24522 7.24522 1.75 14 1.75C20.7548 1.75 26.25 7.24522 26.25 14ZM13.3814 8.13137C13.7233 7.78947 14.2769 7.78947 14.6186 8.13137L18.9936 12.5064C19.1645 12.6772 19.25 12.9012 19.25 13.125C19.25 13.3488 19.1645 13.5728 18.9936 13.7436C18.6517 14.0855 18.0981 14.0855 17.7564 13.7436L14.875 10.8622L14.875 19.25C14.875 19.7332 14.4837 20.125 14 20.125C13.5163 20.125 13.125 19.7332 13.125 19.25L13.125 10.8622L10.2436 13.7436C9.90172 14.0855 9.34806 14.0855 9.00637 13.7436C8.66469 13.4017 8.66447 12.8481 9.00637 12.5064L13.3814 8.13137Z"
                            fill="#307BC4"
                          />
                        </svg>
                      </span>
                    </h2>
                    <div
                      className="cs_accordian_body"
                      style={{
                        display:
                          index === activePaymentIndex ? "block" : "none",
                      }}
                    >
                      <p>
                        {section.content
                          ? section.content
                          : "Coming soon! Awaited content to be revealed shortly."}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_height_42 cs_height_xl_25"></div>
      <Footer />
    </>
  );
}
