import React from "react";

import "../CalculatorTabs/calculators";

function Bodyfat() {
  function ClearForm(form) {
    form.weight.value = "";
    form.waist.value = "";
    form.bodyfat.value = "";
  }
  function BodyFatCal(form) {
    // Holds whether or not the form is correct
    var Correct = true;

    // Check for missing fields
    if (
      form.weight.value == null ||
      form.weight.value.length == 0 ||
      form.waist.value == null ||
      form.waist.value.length == 0
    ) {
      alert("\nPlease fill in all of the form elements");
      Correct = false;
    }

    // Check that only numbers are in the weight and waist fields
    else {
      // Holds the floats representing the weight and waist
      var TestWeight;
      var TestWaist;

      // Parse the weight and waist values and put in the variables
      TestWeight = parseFloat(form.weight.value);
      TestWaist = parseFloat(form.waist.value);

      // Check to see that TestWeight is a number
      if (isNaN(TestWeight)) {
        alert("\nMake sure to enter the weight\nas a number.");
        Correct = false;
        form.weight.value = "";
      }

      // Check to see that TestWaist is a number
      if (isNaN(TestWaist)) {
        alert("\nMake sure to enter the waist size\nas a number.");
        Correct = false;
        form.waist.value = "";
      }

      // Check that the TestWeight is greater than 0
      if (TestWeight <= 0) {
        alert("\nCome on.Enter a real weight.");
        Correct = false;
        form.weight.value = "";
      }

      // Check that the TestWaist is greater than 0
      if (TestWaist <= 0) {
        alert("\nCome on.Enter a real waist size.");
        Correct = false;
        form.waist.value = "";
      }
    }

    // If the inputs are correct, calculate the bodyfat percent
    if (Correct) {
      TestWeight = TestWeight * 2.20462;
      //alert(TestWeight);

      // Holds the bodyfat number and percent
      var BF;
      var BFPercent;

      // Make the male calculations
      if (form.gender[0].checked) {
        BF = -98.42 + 4.15 * TestWaist - 0.082 * TestWeight;
      }

      // Make the female calculations
      else {
        BF = -76.76 + 4.15 * TestWaist - 0.082 * TestWeight;
      }

      // Calculate the bodyfat percentage
      BFPercent = BF / TestWeight;
      BFPercent = BFPercent * 100;
      BFPercent = Math.round(BFPercent);

      // Send this number to the screen
      form.bodyfat.value = BFPercent + "%";
    }
  }

  return (
    <>
      <div id="body_fat_tab">
        <div className="row">
          <div className="col-md-6">
            <h3>Why Body Fat Measurement is Important?</h3>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              Body fat is an important element in successful weight control.
              Body fat requires fewer calories than muscle. So the less fat/more
              muscle we have, the more calories we can eat before gaining
              weight. Use our body fat calculator to find out your approximate
              body fat percentage.
            </p>
            <div className="clearfix">
              <br />
            </div>
            <h3>Body Fat Percentage and Health</h3>
            <p style={{ fontSize: 16, textAlign: "justify" }}>
              The higher your percentage of fat above average levels, the higher
              your health risk for weight-related illness, like heart disease,
              high blood pressure, gallstones, type 2 diabetes, osteoarthritis,
              and certain cancers. Also, the higher your percentage of fat (and
              the smaller your percentage of muscle) the fewer calories you need
              to maintain your weight and therefore the easier it is to gain
              weight. This is because muscle is more metabolically active than
              fat tissue.
            </p>
          </div>
          <div className="col-lg-6">
            <div style={{ width: "100%" }}>
              <div className="body-fat-ratio-heading">
                <h5>Body Fat Ratio</h5>
              </div>
              <div style={{ backgroundColor: "#F7F7F7" }}>
                <div className="clearfix">
                  <br />
                </div>
                <form
                  style={{
                    width: "95%",
                    marginLeft: "15px",
                    marginTop: "20px",
                  }}
                >
                  <div className="row" style={{ borderBottom: "0px" }}>
                    <div className="col-lg-12 mb-4">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Weight In Kg
                      </label>
                      <input
                        className="form-control"
                        id="weight"
                        name="weight"
                        placeholder="Weight"
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Waist Size In Inches
                      </label>
                      <input
                        className="form-control"
                        id="waist"
                        name="waist"
                        placeholder="Waist Size"
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Gender
                      </label>
                      <div className="input-group">
                        <input
                          name="gender"
                          id="gender"
                          value="SelectMale"
                          //   checked=""
                          type="radio"
                        />
                        <label
                          htmlFor="radio_1"
                          style={{ paddingRight: "15px" }}
                          className="ml-1"
                        >
                          Male
                        </label>

                        <input
                          name="gender"
                          id="gender"
                          value="SelectFemale"
                          //   checked=""
                          type="radio"
                        />
                        <label htmlFor="radio_1" className="ml-1">
                          Female
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label
                        className="control-label mb-10"
                        htmlFor="exampleInputuname_1"
                      >
                        Body Fat In Percentage
                      </label>
                      <input
                        className="form-control"
                        id="bodyfat"
                        name="bodyfat"
                        placeholder="Body Fats"
                        type="text"
                      />
                    </div>
                    <div className="col-lg-12 col-lg-offset-3 d-flex justify-content-around ">
                      <button
                        type="button"
                        onClick={() => BodyFatCal(document.forms[0])}
                        className="calculate"
                      >
                        Calculate
                      </button>
                      <button
                        type="button"
                        onClick={() => ClearForm(document.forms[0])}
                        className="clear "
                      >
                        Clear
                      </button>
                    </div>
                    <div className="clearfix">
                      <br />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="clearfix">
            <br />
          </div>
          <div className="col-lg-12">
            <h3>Body Fat Percentage Comparison Table</h3>
            <table class="table table-bordered">
              <thead>
                <tr style={{ backgroundColor: "#37A3EC" }}>
                  <th>Fat Level</th>
                  <th>Men (%)</th>
                  <th>Women (%)</th>
                </tr>
              </thead>
              <tbody>
                <tr align="middle">
                  <td>Very Low</td>
                  <td>7-10</td>
                  <td>14-17</td>
                </tr>
                <tr align="middle">
                  <td>Low</td>
                  <td>10-13</td>
                  <td>17-20</td>
                </tr>
                <tr align="middle">
                  <td>Average</td>
                  <td>13-17</td>
                  <td>20-27</td>
                </tr>
                <tr align="middle">
                  <td>High</td>
                  <td>17-25</td>
                  <td>27-31</td>
                </tr>
                <tr align="middle">
                  <td>Very High</td>
                  <td>above 25</td>
                  <td>above 31</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default Bodyfat;
