import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function HospitalSideNav() {
  const [isAboutMenuVisible, setAboutMenuVisible] = useState(false);
  const [isCareerMenuVisible, setCareerMenuVisible] = useState(false);
  const [isSubMenuVisible, setSubMenuVisible] = useState(false);

  const sitedata = useSelector((store) => store.global.loginData);

  const handleSubMenuToggle = () => {
    setSubMenuVisible(!isSubMenuVisible);
  };
  const handleAboutMenuToggle = () => {
    setAboutMenuVisible(!isAboutMenuVisible);
  };

  const handleCareerMenuToggle = () => {
    setCareerMenuVisible(!isCareerMenuVisible);
  };
  const patientLogin = useSelector((state) => state.patient.patientLogin);

  return (
    <div>
      <div className="mobile-view-links">
        <ul className="">
          <li>
            <Link to="/">Home</Link>
          </li>

          <li
            // className="menu-item-has-children"
            onClick={handleAboutMenuToggle}
          >
            <span>About Us +</span>
            {isAboutMenuVisible && (
              <ul className="mobile_view_li_links">
                <li>
                  <Link to="/about">Introduction</Link>
                </li>
                {sitedata?.options?.patient_nav === "show" && (
                  <li>
                    <Link to="/reviews">Reviews</Link>
                  </li>
                )}

                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
              </ul>
            )}
          </li>

          <li className="" onClick={handleSubMenuToggle}>
            <span>Patient Care +</span>
            {isSubMenuVisible && (
              <ul className="mobile_view_li_links">
                <li>
                  <Link to="/doctors">Doctors</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/facilities">Facilities</Link>
                </li>
                <li>
                  <Link to="/e_health">E Health</Link>
                </li>
              </ul>
            )}
          </li>

          <li className="" onClick={handleCareerMenuToggle}>
            <span>Career +</span>
            {isCareerMenuVisible && (
              <ul className="mobile_view_li_links">
                <li>
                  <Link to="/careers">Jobs</Link>
                </li>
                <li>
                  <Link to="/drop-cv">Drop Your CV</Link>
                </li>
              </ul>
            )}
          </li>

          <li>
            <Link to="/complaints">Feedback</Link>
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
        
        </ul>
      </div>
    </div>
  );
}

export default HospitalSideNav;
