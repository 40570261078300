import React, { useEffect, useState } from "react";
import Header from "../Header";
import ThirdFooter from "../Footer/ThirdFooter";
import { SendPostRequest } from "../../http";
import Spinner from "../Spinner";
import { capitalize } from "@mui/material";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import SecondFooter from "../Footer/SecondFooter";

function Reviews() {
  const sitedata = useSelector((store) => store.global.loginData);
  const [reviewsData, setReviewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);

  useEffect(() => {
    const title = sitedata?.site_title || "";
    document.title = capitalize(title) + " :: Reviews - ClinTa";
    window.scrollTo(0, 0);
    getReviewsData();
  }, []);

  const getReviewsData = async () => {
    try {
      setIsLoading(true);
      const response = await SendPostRequest("reviews");
      setReviewsData(response?.data);
    } catch (error) {
      console.error("Error fetching reviews:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const handleShowMore = (review) => {
    setSelectedReview(review);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedReview(null);
  };

  return (
    <>
      <Header />
      <div className="page-title-wrap typo-white">
        <div
          className="page-title-wrap-inner section-bg-img"
          style={{
            backgroundImage: "url(/assets/images/bg/page-title-bg.jpg)",
          }}
        >
          <span className="black-overlay"></span>
          <div className="container">
            <div className="row text-left">
              <div className="col-md-12">
                <div className="page-title-inner">
                  <h1 className="page-title mb-0">Reviews</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        {reviewsData?.length === 0 ? (
          <h2 className="my-4 text-center">
            Congratulations on being the first to review! Your feedback means a
            lot to us.
          </h2>
        ) : (
          <h2 className="my-4 text-center">WHAT PATIENTS ARE SAYING</h2>
        )}
        <div className="row testimonails" data-animation="fadeInRight">
          {isLoading ? (
            <div className="d-flex align-items-center justify-content-center">
              <Spinner />
            </div>
          ) : reviewsData?.length === 0 ? (
            <div
              //   style={{ marginBottom: "100px" }}
              className="d-flex align-items-center justify-content-center"
            ></div>
          ) : (
            reviewsData.map((data, index) => (
              <div
                key={index}
                className="col-lg-4 testimonial-style-5 dark-switch item mb-4"
              >
                <div
                  className="client-box text-left"
                  style={{ height: "250px" }}
                >
                  <div className="client-image">
                    <img
                      className="img-circle img-thumbnail"
                      src={
                        data?.profile_image || "/assets/images/bg/page-title-bg.jpg"
                      }
                      alt="testimonials"
                      width="65"
                      height="65"
                      style={{height: '65px'}}
                    />
                  </div>
                  <p>
                    {truncateText(data?.detail, 20)}
                    {data?.detail?.split(" ").length > 20 && (
                      <Link
                        onClick={() => handleShowMore(data)}
                        className="fw-bold"
                      >
                        See More
                      </Link>
                    )}
                  </p>
                  <div className="client-details">
                    <strong className="theme-color mb-2">
                      {data?.full_name}
                    </strong>
                    {/* <span>CEO</span> */}
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Review Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedReview && (
            <>
              <p>{selectedReview?.detail}</p>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {sitedata?.theme_footer === "version_1" ? <SecondFooter /> : <ThirdFooter />}
    </>
  );
}

export default Reviews;
