import React, { useState } from "react";
import { Navbar, Nav } from "react-bootstrap";

function ProfileTabs({ onTabSelect, selectedTab, setSelectedTab }) {
  // const [selectedTab, setSelectedTab] = useState("WeightManagment");
  const handleNavItemClicked = (heading) => {
    onTabSelect(heading);
    // setOpenDropdown(null);
    setSelectedTab(heading); // Close the dropdown when a tab is clicked
  };
  return (
    <div className="profileTabs">
      <Navbar bg="" expand="lg" variant="dark">
        <Navbar.Toggle aria-controls="main_nav" />
        <Navbar.Collapse id="main_nav">
          <Nav className="mr-auto p-3">
            <Nav.Link
              className={selectedTab === "WeightManagment" ? "active" : ""}
              onClick={() => handleNavItemClicked("WeightManagment")}
            >
              Weight Managment
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "BloodGlucose" ? "active" : ""}
              onClick={() => handleNavItemClicked("BloodGlucose")}
            >
              Blood Glucose
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "BloodPressure" ? "active" : ""}
              onClick={() => handleNavItemClicked("BloodPressure")}
            >
              Blood Pressure
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "UpdateProfile" ? "active" : ""}
              onClick={() => handleNavItemClicked("UpdateProfile")}
            >
              Update Profile
            </Nav.Link>
            <Nav.Link
              className={selectedTab === "UpdateAccount" ? "active" : ""}
              onClick={() => handleNavItemClicked("UpdateAccount")}
            >
              Update Account
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}

export default ProfileTabs;
