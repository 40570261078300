import {
  LOGIND_DETAIL,
  DOCTOR_DETAIL,
  PROFILE_CHECK,
  SET_PATIENT_PROFILE,
  SET_PATIENT_LOGIN,
  SET_PATIENT_LOGOUT,
} from "./actionTypes";

const INIT_STATE = {
  loginData: {},
  profileCheck: {},
  patientProfile: {},
  selectedDoctor: {},
};

const Global = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIND_DETAIL:
      return {
        ...state,
        loginData: action.payload,
      };

    case PROFILE_CHECK:
      return {
        ...state,
        profileCheck: action.payload,
      };
    case SET_PATIENT_PROFILE:
      return {
        ...state,
        patientProfile: action.payload,
      };

    case DOCTOR_DETAIL:
      return {
        ...state,
        selectedDoctor: action.payload,
      };

    default:
      return state;
  }
};

export default Global;
