import React, { useState, useEffect } from "react";
import "./FourthMainBanner.css";
import { SendPostRequest } from "../../http";
import { useSelector } from "react-redux";

const FourthMainBanner = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [homeBanners, setHomeBanners] = useState([]);
  const sitedata = useSelector((store) => store.global.loginData);

  useEffect(() => {
    brandingData();
  }, []);

  const brandingData = async () => {
    const siteTheme = sitedata?.options?.theme;
    const response = await SendPostRequest("branding/banners", {
      theme: siteTheme,
    });
    setHomeBanners(response?.data);
    // if (!response?.data || response?.data?.length < 1) {
    //   setIsdata(true);
    // } else {
    //   setHomeBanners(response?.data);
    // }
  };

  const handleSlideChange = (index) => {
    setActiveIndex(index);
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) =>
      prevIndex === 0 ? homeBanners.length - 1 : prevIndex - 1
    );
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % homeBanners.length);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % homeBanners.length);
    }, 8000); // Change slide every 5 seconds

    return () => clearInterval(interval);
  }, [homeBanners.length]);

  return (
    <div className="slider-four">
      <div className="four-shadow"></div>
      {homeBanners.map((data, index) => (
        <img
          key={index}
          decoding="async"
          className={`img-slide ${index === activeIndex ? "active" : ""}`}
          src={`${data?.banner_image}`}
          alt={`Slide ${index + 1}`}
        />
      ))}
      {homeBanners.map((data, index) => (
        <div
          key={index}
          className={`content-text ${index === activeIndex ? "active" : ""}`}
        >
          <h1 className="animated-text">{data?.banner_title}</h1>
          <p className="animated-text">{data?.banner_description}</p>
        </div>
      ))}
      <div className="four-slider-navigation">
        <button className="four-prev-btn" onClick={handlePrevClick}>
          &#10094;
        </button>
        <button className="four-next-btn" onClick={handleNextClick}>
          &#10095;
        </button>
        {/* Comment out the dots if not needed
        {homeBanners.map((_, index) => (
          <div
            key={index}
            className={`nav-btn ${index === activeIndex ? "active" : ""}`}
            onClick={() => handleSlideChange(index)}
          ></div>
        ))} */}
      </div>
    </div>
  );
};

export default FourthMainBanner;
