import React, { useState } from "react";
import Header from "./Header";
import { SendPostRequest } from "../http";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

function ForgetPassword() {
  let { slug } = useParams();

  const navigate = useNavigate();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [slug1, setSlug1] = useState(slug);

  const handleChangeNewPassword = (e) => {
    setNewPassword(e.target.value);
  };
  const handleChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleClickForgotEmail = async (e) => {
    e.preventDefault();

    if (!newPassword) {
      toast.error("Enter Your New Password");
      return
    } else if (!confirmPassword) {
      toast.error("Confirm Your Password");
      return
    } else if (newPassword === confirmPassword) {
      try {
        const response = await SendPostRequest("auth/forget_password", {
          action: "change_password",
          verify_code: slug1,
          new_password: newPassword,
        });
        
        toast.success("Password Changed Successfully");
        navigate("/login")

        console.log("email forgto res");
      } catch (error) {
        toast.error("Login failed. Please check your credentials.");
        console.error("Login failed:", error);
      }
    } else {
      toast.error("Password doesn't match");
      return
    }
  };

  return (
    <>
      <Header />
      <div className="cs_height_42 cs_height_xl_25"></div>
      <div className="cs_height_42 cs_height_xl_25"></div>

      <section>
        <div className="cs_height_42 cs_height_xl_25"></div>

        <div className="cs_height_42 cs_height_xl_25"></div>
        <div className="row">
          <div className="container col-lg-12">
            <div className="row">
              <div className="col-lg-10 offset-lg-1">
                <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
                  <div className="row">
              
                    <h1>Patient Reset Password</h1>

                    <div className="col-lg-12 pl-0">
                      <label className="cs_input_label cs_heading_color">
                        New Password
                      </label>
                      <input
                        name="new_password"
                        type="password"
                        className="cs_form_field"
                        placeholder="New Password"
                        onChange={handleChangeNewPassword}
                        required
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-12 pl-0">
                      <div className="forget-password">
                        <label className="cs_input_label cs_heading_color">
                          Confirm Password
                        </label>
                      </div>
                      <input
                        type="password"
                        className="cs_form_field"
                        placeholder="Confirm Password"
                        name="new_password"
                        onChange={handleChangeConfirmPassword}
                        required
                      />
                      <div className="cs_height_42 cs_height_xl_25"></div>
                    </div>
                    <div className="col-lg-12 ">
                      <div className="cs_height_18"></div>
                      <button
                        className="cs_btn cs_style_1 "
                        onClick={handleClickForgotEmail}
                      >
                        <span>Change Password</span>
                        <i>
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                          <img
                            src="/assets/img/icons/arrow_white.svg"
                            alt="Icon"
                          />
                        </i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ForgetPassword;
