import React from "react";

function HeightCounter() {
  function doMath() {
    if (document.form1.qft.value == "") {
      var one = 0;
    } else {
      var one = eval(document.form1.qft.value);
    }
    if (document.form1.qin.value == "") {
      var two = 0;
    } else {
      var two = eval(document.form1.qin.value);
    }
    var fac = eval(document.form1.factor.value);
    var prod = (one * 12 + two) * fac * 100;
    var mt = prod;
    document.form1.qm.value = mt.toFixed(0);
  }

  function doMath2() {
    if (document.form1.qm.value == "") {
      var one = 0;
    } else {
      var one = eval(document.form1.qm.value);
    }
    var fac = eval(document.form1.factor.value);
    var prod = one / fac / 100;
    var ft = parseInt(prod / 12);
    var inch = prod % 12;
    document.form1.qft.value = ft.toFixed(0);
    document.form1.qin.value = inch.toFixed(0);
  }

  function doMath3() {
    if (document.form2.qft2.value == "") {
      var one = 0;
    } else {
      var one = eval(document.form2.qft2.value);
    }
    if (document.form2.qin2.value == "") {
      var two = 0;
    } else {
      var two = eval(document.form2.qin2.value);
    }
    var fac = eval(document.form2.factor.value);
    var prod = (one * 12 + two) * fac * 100;
    var mt = prod;
    document.form2.qm2.value = mt.toFixed(0);
  }
  function doMath4() {
    if (document.form2.qm2.value == "") {
      var one = 0;
    } else {
      var one = eval(document.form2.qm2.value);
    }
    var fac = eval(document.form2.factor.value);
    var prod = one / fac / 100;
    var ft = parseInt(prod / 12);
    var inch = prod % 12;
    document.form2.qft2.value = ft.toFixed(0);
    document.form2.qin2.value = inch.toFixed(0);
  }

  function doMath5() {
    if (document.form3.qm3.value == "") {
      var one = 0;
    } else {
      var one = eval(document.form3.qm3.value);
    }
    var fac = eval(document.form3.factor.value);
    var prod = one / fac / 100;
    var ft = parseInt(prod / 12);
    var inch = prod % 12;
    document.form3.qft3.value = ft.toFixed(0);
    document.form3.qin3.value = inch.toFixed(0);
  }

  function doMath6() {
    if (document.form3.qm3.value == "") {
      var one = 0;
    } else {
      var one = eval(document.form3.qm3.value);
    }
    var fac = eval(document.form3.factor.value);
    var prod = one / fac / 100;
    var ft = parseInt(prod / 12);
    var inch = prod % 12;
    document.form3.qft3.value = ft.toFixed(0);
    document.form3.qin3.value = inch.toFixed(0);
  }
  return (
    <>
      <div id="height_conveter_calc_tab" role="tabpanel">
        <div class="row">
          <div id="converter">
            <div class="row">
              <div class="col-md-4">
                <div style={{ width: "100%" }}>
                  <div className="body-fat-ratio-heading">
                    <h5> feet and inches = centimeters</h5>
                  </div>

                  <div style={{ backgroundColor: "#F7F7F7" }}>
                    <div class="clearfix">
                      <br />
                    </div>
                    <form
                      style={{
                        width: "95%",
                        marginLeft: "8px",
                        marginTop: "5px",
                      }}
                      name="form1"
                    >
                      <input name="factor" value="0.0254" type="hidden" />
                      <div class="row" style={{ borderBottom: "0px" }}>
                        <div class="col-lg-12 mb-3">
                          <label
                            class="control-label mb-10"
                            for="exampleInputuname_1"
                          >
                            Feet
                          </label>
                          <input
                            name="qft"
                            class="inputbox form-control"
                            id="qft"
                            tabindex="1"
                            onKeyUp={() => doMath()}
                            maxlength="5"
                            type="text"
                          />
                        </div>
                        <div class="col-lg-12 mb-3">
                          <label
                            class="control-label mb-10"
                            for="exampleInputuname_1"
                          >
                            Inches
                          </label>
                          <input
                            name="qin"
                            class="inputbox form-control"
                            id="qin"
                            tabindex="1"
                            onKeyUp={() => doMath()}
                            maxlength="5"
                            type="text"
                          />
                        </div>
                        <div class="col-lg-12 ">
                          <label
                            class="control-label mb-10"
                            for="exampleInputuname_1"
                          >
                            Centimeters
                          </label>
                          <input
                            name="qm"
                            class="inputbox form-control"
                            id="qm"
                            tabindex="1"
                            onKeyUp={() => doMath2()}
                            maxlength="5"
                            type="text"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div style={{ width: "100%" }}>
                  <div className="body-fat-ratio-heading">
                    <h5> centimeters = feet and inches</h5>
                  </div>

                  <div style={{ backgroundColor: "#F7F7F7" }}>
                    <div className="clearfix">
                      <br />
                    </div>
                    <form
                      style={{
                        width: "95%",
                        marginLeft: "8px",
                        marginTop: "5px",
                      }}
                      name="form2"
                    >
                      <input name="factor" value="0.0254" type="hidden" />
                      <div className="row" style={{ borderBottom: "0px" }}>
                        <div className="col-lg-12 mb-3">
                          <label
                            className="control-label mb-10"
                            htmlFor="exampleInputuname_1"
                          >
                            Centimeters
                          </label>
                          <input
                            name="qm2"
                            className="inputbox form-control"
                            id="qm2"
                            tabIndex="1"
                            onKeyUp={() => doMath4()}
                            maxLength="5"
                            type="text"
                          />
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label
                            className="control-label mb-10"
                            htmlFor="exampleInputuname_1"
                          >
                            Feet
                          </label>
                          <input
                            name="qft2"
                            className="inputbox form-control"
                            id="qft2"
                            tabIndex="1"
                            onKeyUp={() => doMath3()}
                            maxLength="5"
                            type="text"
                          />
                        </div>
                        <div className="col-lg-12 ">
                          <label
                            className="control-label mb-10"
                            htmlFor="exampleInputuname_1"
                          >
                            Inches
                          </label>
                          <input
                            name="qin2"
                            className="inputbox form-control"
                            id="qin2"
                            tabIndex="1"
                            onKeyUp={() => doMath3()}
                            maxLength="2"
                            type="text"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div style={{ width: "100%" }}>
                  <div className="body-fat-ratio-heading">
                    <h5> inches = feet and inches</h5>
                  </div>

                  <div style={{ backgroundColor: "#F7F7F7" }}>
                    <div className="clearfix">
                      <br />
                    </div>
                    <form
                      style={{
                        width: "95%",
                        marginLeft: "8px",
                        marginTop: "5px",
                      }}
                      name="form3"
                    >
                      <input name="factor" value="0.01" type="hidden" />
                      <div className="row" style={{ borderBottom: "0px" }}>
                        <div className="col-lg-12 mb-3">
                          <label
                            className="control-label mb-10"
                            htmlFor="exampleInputuname_1"
                          >
                            Inches
                          </label>
                          <input
                            name="qm3"
                            className="inputbox form-control"
                            id="qm3"
                            tabIndex="1"
                            onKeyUp={() => doMath5()}
                            maxLength="7"
                            type="text"
                          />
                        </div>
                        <div className="col-lg-12 mb-3">
                          <label
                            className="control-label mb-10"
                            htmlFor="exampleInputuname_1"
                          >
                            Feet
                          </label>
                          <input
                            name="qft3"
                            className="inputbox form-control"
                            id="qft3"
                            tabIndex="1"
                            onKeyUp={() => doMath6()}
                            maxLength="5"
                            type="text"
                          />
                        </div>
                        <div className="col-lg-12">
                          <label
                            className="control-label mb-10"
                            htmlFor="exampleInputuname_1"
                          >
                            Inches
                          </label>
                          <input
                            name="qin3"
                            className="inputbox form-control"
                            id="qin3"
                            tabIndex="1"
                            onKeyUp={() => doMath3()}
                            maxLength="2"
                            type="text"
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="converter" className="row pad ">
            <div className="col-md-5 ad_2">
              <script
                type="text/javascript"
                async=""
                src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
              ></script>
              {/* <!-- height-converter-auto --> */}
              <ins
                className="adsbygoogle"
                style={{ display: "block" }}
                data-ad-client="ca-pub-0063753318513298"
                data-ad-slot="4745843656"
                data-ad-format="auto"
                data-adsbygoogle-status="done"
              ></ins>
              <script>{`(adsbygoogle=window.adsbygoogle||[]).push({});`}</script>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeightCounter;
