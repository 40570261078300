import {
  DOCTOR_DETAIL,
  LOGIND_DETAIL,
  PROFILE_CHECK,
  SET_PATIENT_PROFILE,
  SET_PATIENT_LOGIN,
  SET_PATIENT_LOGOUT,
} from "./actionTypes";

export const setLogindata1 = (payload) => ({
  type: LOGIND_DETAIL,
  payload,
});

export const setProfileCheck = (payload) => ({
  type: PROFILE_CHECK,
  payload,
});

export const setSelectedDoctor = (payload) => ({
  type: DOCTOR_DETAIL,
  payload,
});

export const setPatientProfile = (payload) => {
  return {
    type: SET_PATIENT_PROFILE,
    payload,
  };
};
export const setPatientLogin = (payload) => {
  return {
    type: SET_PATIENT_LOGIN,
    payload,
  };
};

export const setPatientLogout = (payload) => {
  return {
    type: SET_PATIENT_LOGOUT,
    payload,
  };
};
